import makeApiCall from "../../apis";

export const USER_ADDRESS_LOADING = "USER_ADDRESS_LOADING";
export const USER_ADDRESS_SUCCESS = "USER_ADDRESS_SUCCESS";
export const USER_ADDRESS_ERROR = "USER_ADDRESS_ERROR";

export const getAddressLoading = () => ({
  type: USER_ADDRESS_LOADING,
});

export const getAddressSuccess = (payload) => ({
  type: USER_ADDRESS_SUCCESS,
  payload,
});

export const getAddressError = () => ({
  type: USER_ADDRESS_ERROR,
});

export const getAddressData = () => (dispatch) => {
  dispatch(getAddressLoading());
  makeApiCall(`/address`, {
    method: "GET",
  })
    .then((res) => {
      console.log("Address", res);
      dispatch(getAddressSuccess(res));
    })
    .catch((error) => dispatch(getAddressError()));
};
