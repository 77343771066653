import { imageUrl } from "../constants";

const Logo = () => {
  return (
    <img
      style={{ width: "100%" }}
      src={`${imageUrl}/logo/logo.png`}
      alt="logo"
    />
  );
};

export default Logo;
