import { Box } from "@mui/material";
import { TotalAmount } from "./TotalAmount";

const Wrapper = ({ children, btnText, redirectLink, isActive, isShow }) => {
  return (
    <Box
      sx={{
        width: { xs: "95%", md: "70%" },
        m: "auto",
        mt: 6,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
        gap: 3,
      }}
    >
      {children}
      <Box sx={{ width: { xs: "100%", md: "30%" } }}>
        <TotalAmount
          btnText={btnText}
          redirectLink={redirectLink}
          isActive={isActive}
          isShow={isShow}
        />
      </Box>
    </Box>
  );
};

export default Wrapper;
