export const priceFormat = (amount, exchangeRate, currency) => {
  if (currency === "INR" || currency === "")
    return `₹${Intl.NumberFormat("en-IN").format(amount)}`;
  const usdAmount = (amount / exchangeRate).toFixed(2);
  return `${curSymbol[currency] ? curSymbol[currency] : "$"}${usdAmount}`;
};

export const discountedPrice = (amount, discount) => {
  const currency = "₹";
  const totalValue = (amount - amount * (discount / 100)).toFixed(2);
  return `${currency}${Intl.NumberFormat("en-IN").format(totalValue)}`;
};

const curSymbol = {
  INR: "₹",
  USD: "$",
  GBP: "£",
  EUR: "€",
};
