import { Box } from "@mui/material";
import {
  returnsAndExchangesSecond,
  returnsAndExchangesFirst,
} from "./constent";
import tableImage from "./assets/table.png";

const HTMLContent = ({ htmlString }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

const ReturnsAndExchanges = () => {
  return (
    <Box sx={{ width: { xs: "90%", sm: "70%", md: "60%" }, m: "auto" }}>
      <HTMLContent htmlString={returnsAndExchangesFirst} />
      <Box
        component={"img"}
        src={tableImage}
        sx={{ my: 2, width: "80%", m: "auto" }}
      />
      <HTMLContent htmlString={returnsAndExchangesSecond} />
    </Box>
  );
};

export default ReturnsAndExchanges;
