import { Add } from "@mui/icons-material";
import {
  Drawer,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ProgressIndicator } from "../../loading/ProgressIndicator";
import { getFilterOption } from "../../Redux/subMenuOption/action";
import { pages } from "./constants";
import SubMenuOption from "./SubMenuOption";

const MenuDrawer = ({ open, setOpen }) => {
  const [index, setIndex] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subMenu, setSubMenu] = useState({
    category: [],
    designer: [],
  });

  const { allCategory, allDesigner, loading } = useSelector(
    (state) => state.filterOption
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleCollapse = (type, ind) => {
    dispatch(getFilterOption(type));
    setIndex(ind);
  };

  const handleNavigate = (route) => {
    navigate(`products/${route.toLowerCase()}`);
    setOpen(false);
  };

  useEffect(() => {
    setSubMenu({
      category: [...allCategory],
      designer: [...allDesigner],
    });
  }, [allCategory, allDesigner, loading]);

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { width: "50%", p: 1 },
      }}
    >
      <List
        sx={{
          color: "#000000",
          fontSize: "16px",
        }}
      >
        {pages.map((page, ind) => (
          <>
            <ListItem
              disablePadding
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => {
                    handleCollapse(page.toLowerCase(), ind);
                    ind === index && handleCollapse(null);
                  }}
                >
                  <Add />
                </IconButton>
              }
            >
              <ListItemButton
                sx={{ bgcolor: index === ind ? "#E5FDD1" : "transparent" }}
                key={page}
                onClick={() => {
                  handleNavigate(page);
                }}
              >
                <ListItemText primary={page} />
              </ListItemButton>
            </ListItem>
            <Collapse in={index === ind}>
              {loading ? (
                <ProgressIndicator />
              ) : (
                <SubMenuOption subMenu={subMenu} type={page} />
              )}
            </Collapse>
          </>
        ))}
      </List>
    </Drawer>
  );
};

export default MenuDrawer;
