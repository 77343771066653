import { Outlet } from "react-router-dom";
import { Footer, Header } from "./pages";
import { Box } from "./uiCore/elements";
import useAppInitializer from "./app/useAppInitializer";

const AppLayout = () => {
  useAppInitializer();

  return (
    <Box>
      <Header />
      <Box sx={{ width: "90%", margin: "auto" }}>
        <main>
          <Outlet />
        </main>
      </Box>
      <Footer />
    </Box>
  );
};

export default AppLayout;
