import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { CloseOutlined } from "@mui/icons-material";
import SizeComponent from "./size/SizeComponent";
import MetaComponent from "./meta/MetaComponent";
import { bodySizeChart, occasionOption, productSizeChart } from "./constant";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getCategoryData,
  getDesignerData,
} from "../../Redux/categoryDesigner/action";

const ProductForm = ({
  handleSubmit,
  data,
  loading,
  handleChangeSize,
  handleImage,
  handleFieldChange,
  handleRemoveSize,
  size,
  meta,
  handleChangeMeta,
  handleRemoveMeta,
}) => {
  const dispatch = useDispatch();
  const { category } = useSelector((state) => state.category);
  const { designer } = useSelector((state) => state.designer);
  const [uniqueCate, setUniqueCate] = useState([]);

  useEffect(() => {
    dispatch(getCategoryData());
    dispatch(getDesignerData());
  }, [dispatch]);

  useEffect(() => {
    const unique = [...new Set(category.map((item) => item.category))];
    setUniqueCate([...unique]);
  }, [category]);

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
          gap: 5,
          mt: 3,
        }}
      >
        <TextField
          value={data.title}
          name="title"
          placeholder="Title"
          label="Title"
          onChange={handleFieldChange}
          fullWidth
          required
        />

        <TextField
          value={data.description}
          name="description"
          placeholder="Description"
          label="Description"
          multiline
          rows={3}
          onChange={handleFieldChange}
          fullWidth
          required
        />

        <Box sx={{ display: "flex", gap: 4 }}>
          <FormControl fullWidth sx={{ mt: 3 }}>
            <InputLabel>Category</InputLabel>
            <Select
              value={data.category}
              label="Category"
              name="category"
              onChange={handleFieldChange}
            >
              {uniqueCate.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <InputLabel>Designer</InputLabel>
            <Select
              value={data.designer}
              label="Designer"
              name="designer"
              onChange={handleFieldChange}
            >
              {designer.map((item) => (
                <MenuItem value={item.designer}>{item.designer}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", gap: 4 }}>
          <FormControl fullWidth sx={{ mt: 3 }}>
            <InputLabel>Type</InputLabel>
            <Select
              value={data.type}
              label="Type"
              name="type"
              onChange={handleFieldChange}
            >
              <MenuItem value="men">Men</MenuItem>
              <MenuItem value="women">Women</MenuItem>
              <MenuItem value="boys">Boys</MenuItem>
              <MenuItem value="girls">Girls</MenuItem>
              <MenuItem value="girls & boys">Girls & Boys</MenuItem>
              <MenuItem value="kids">Kids</MenuItem>
              <MenuItem value="designer">Designer</MenuItem>
              <MenuItem value="jewellery">Jewellery</MenuItem>
              <MenuItem value="cosmetics">Cosmetics</MenuItem>
              <MenuItem value="decor">Decor</MenuItem>
              <MenuItem value="gifts">Gifts</MenuItem>
              <MenuItem value="sale">Sale</MenuItem>
              <MenuItem value="occasion">Occasion</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={data.isActive}
              label="Status"
              name="isActive"
              onChange={handleFieldChange}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", gap: 4 }}>
          <TextField
            name="color"
            label="Color"
            value={data.color}
            placeholder="Red"
            onChange={handleFieldChange}
            fullWidth
            required
          />
          <TextField
            value={data.subcategory}
            name="subcategory"
            placeholder="Subcategory"
            label="Subcategory(optional)"
            onChange={handleFieldChange}
            fullWidth
          />
        </Box>

        <Box sx={{ display: "flex", gap: 4 }}>
          <FormControl fullWidth sx={{ mt: 3 }}>
            <InputLabel>Occasion</InputLabel>
            <Select
              value={data.occasion}
              label="Occasion"
              name="occasion"
              onChange={handleFieldChange}
            >
              {occasionOption.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            value={data.shippingTime}
            name="shippingTime"
            placeholder="5"
            label="Shipping Time(In Days)"
            type="number"
            onChange={handleFieldChange}
            fullWidth
          />
        </Box>

        <Box sx={{ display: "flex", gap: 4 }}>
          <TextField
            value={data.netPrice}
            name="netPrice"
            placeholder="price"
            label="Net Price"
            type="number"
            onChange={handleFieldChange}
            fullWidth
            required
          />

          <TextField
            value={data.discount}
            name="discount"
            placeholder="Discount"
            label="Discount"
            type="number"
            onChange={handleFieldChange}
            fullWidth
          />

          <TextField
            value={data.discountedPrice}
            name="discountedPrice"
            placeholder="Discounted Price"
            label="Discounted Price"
            type="number"
            onChange={handleFieldChange}
            fullWidth
          />
        </Box>

        <Box>
          <InputLabel sx={{ mb: 1 }}>
            Size With Price(price is optional)
          </InputLabel>
          <Box>
            {size.map((s, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <SizeComponent
                  handleChangeSize={handleChangeSize}
                  index={index}
                  size={s}
                  sx={{ width: "95%" }}
                />
                {index !== 0 && index !== size.length - 1 ? (
                  <IconButton
                    color="success"
                    sx={{ width: "4%", display: "flex", alignItems: "center" }}
                    onClick={() => {
                      handleRemoveSize(index);
                    }}
                  >
                    <CloseOutlined />
                  </IconButton>
                ) : null}
              </Box>
            ))}
          </Box>
        </Box>

        <Box>
          <InputLabel sx={{ mb: 1 }}>
            Other Details of Product(like:- Tailoring Services: Blouse )
          </InputLabel>
          <Box>
            {meta.map((m, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MetaComponent
                  handleChangeMeta={handleChangeMeta}
                  index={index}
                  meta={m}
                  sx={{ width: "95%" }}
                />
                {index !== 0 && index !== meta.length - 1 ? (
                  <IconButton
                    color="success"
                    sx={{ width: "4%", display: "flex", alignItems: "center" }}
                    onClick={() => {
                      handleRemoveMeta(index);
                    }}
                  >
                    <CloseOutlined />
                  </IconButton>
                ) : null}
              </Box>
            ))}
          </Box>
        </Box>

        <Box>
          <InputLabel sx={{ mb: 1 }}>Thumbnail</InputLabel>
          <Box>
            <Box
              flex={1}
              sx={{
                border: "1px solid #dddddd",
                display: "flex",
                alignItems: "center",
                p: 2,
              }}
            >
              <input
                className="uploadInput"
                accept="image/*"
                type="file"
                name="image"
                required={!data.thumbnail}
                style={{
                  background: data.thumbnail ? "#6ECB63" : "#fff",
                }}
                onChange={handleImage}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 4 }}>
          <FormControl fullWidth sx={{ mt: 3 }}>
            <InputLabel>Size Chart</InputLabel>
            <Select
              value={data.sizeChart}
              label="Size Chart"
              name="sizeChart"
              onChange={handleFieldChange}
            >
              <MenuItem value={""}>Select Chart</MenuItem>
              {productSizeChart.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <InputLabel>Body Size Chart</InputLabel>
            <Select
              value={data.bodySizeChart}
              label="Body Size Chart"
              name="bodySizeChart"
              onChange={handleFieldChange}
            >
              <MenuItem value={""}>Select Chart</MenuItem>
              {bodySizeChart.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            type="submit"
            size="large"
            sx={{ p: "10px 30px 6px", borderRadius: 10 }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ProductForm;
