import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { productNavigator } from "../utils";
import { useNavigate } from "react-router-dom";

export const SliderCard = ({ item }) => {
  const navigate = useNavigate();
  return (
    <Card
      className="product_card"
      sx={{ boxShadow: 0 }}
      onClick={() => {
        productNavigator(item, navigate);
      }}
    >
      <CardMedia
        component="img"
        width="100%"
        image={item.thumbnail}
        alt={item.title}
        sx={{ objectFit: "contain", cursor: "pointer" }}
      />
      <CardContent sx={{ p: 1 }}>
        <Typography
          variant="h4"
          sx={{ fontSize: { xs: 14, sm: 16, md: 18 } }}
          className="ellipsis"
        >
          {`${item.designer}-${item.title}`}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: { xs: 12, sm: 14, md: 16 },
          }}
        >
          {item.description}
        </Typography>
        <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
          <Typography variant="h5">
            ₹{Intl.NumberFormat("en-IN").format(item.netPrice)}
          </Typography>
          {item.discount ? (
            <Typography
              variant="body2"
              sx={{
                fontSize: { xs: 12, sm: 14, md: 16 },
                color: (theme) => theme.palette.primary.main,
              }}
            >
              ({item.discount}% OFF)
            </Typography>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
};
