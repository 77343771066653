import { Box } from "@mui/material";

const themeConfig = {
  palette: {
    primary: {
      main: "#e62e72",
    },
    secondary: {
      main: "rgba(237, 27, 6, 0.3)",
    },
    hover: {
      main: "#D61355",
    },
    error: {
      main: "#FF3236",
    },
    warning: {
      main: "#525C76",
    },
    success: {
      main: "#44AC21",
    },
    text: {
      primary: "#000000",
    },
    icon: {
      main: "#404258",
    },
  },
  typography: {
    fontFamily: [
      "Euclid",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 16,
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: 1.25,
      letterSpacing: "-0.015em",
      color: "#4d4d4d",
    },
    h2: {
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: 1.25,
      letterSpacing: "0.0025em",
      color: "#4d4d4d",
    },
    h3: {
      fontSize: "1.5rem",
      lineHeight: 1.71,
      fontWeight: 700,
      color: "#4d4d4d",
    },
    h4: {
      fontWeight: 700,
      fontSize: "1.25rem",
      lineHeight: 1.25,
      letterSpacing: "0.0015em",
      color: "#4d4d4d",
    },
    h5: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.25,
      letterSpacing: "0.002em",
    },
    h6: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.285712,
      letterSpacing: "0.002em",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.25,
      letterSpacing: "0.0025em",
    },
    body2: {
      fontWeight: 400,
      fontSize: "1.05rem",
      lineHeight: 1.5,
      letterSpacing: "0.005em",
    },
    body3: {
      fontWeight: 400,
      fontSize: "1rem",
      letterSpacing: "0.0025em",
      lineHeight: 1.5,
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(217, 217, 217, 0.5)",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "#323338",
          fontSize: "2rem",
          fontWeight: 500,
        },
      },
      defaultProps: {
        component: Box,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: { width: 41, height: 24, padding: 0 },
        thumb: {
          boxSizing: "border-box",
          width: 18,
          height: 18,
        },
        track: {
          borderRadius: 24 / 2,
          backgroundColor: "#C5C7D0",
          opacity: 1,
          transition: "background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        switchBase: {
          padding: 0,
          margin: 3,
          transitionDuration: "300ms",
          "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
              backgroundColor: "#ED1B06",
              opacity: 1,
              border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
          },
          "&.Mui-disabled .MuiSwitch-thumb": {
            backgroundColor: "#ffffff",
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.7,
            backgroundColor: "#E6E9EF",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& + .MuiFormControlLabel-label": {
            fontSize: "0.875rem",
          },
          "& svg": {
            fill: "#C5C7D0",
            fontSize: "1rem",
          },
          "&:hover svg": {
            fill: "#323338",
          },
          "&.Mui-checked": {
            "& svg": {
              fill: "#ED1B06",
            },
            "&:hover svg": {
              fill: "#B12011",
            },
            "&.Mui-disabled svg": {
              background: "transparent",
            },
          },
          "&.Mui-disabled svg": {
            fill: "#E6E9EF",
            background: "#E6E9EF",
            borderRadius: 1,
          },
        },
      },
      defaultProps: {},
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "& + .MuiFormControlLabel-label": {
            fontSize: "0.875rem",
          },
          "& svg": {
            fill: "#C5C7D0",
            fontSize: "1rem",
          },
          "&:hover svg": {
            fill: "#323338",
          },
          "&.Mui-checked": {
            "& svg": {
              fill: "#ED1B06",
            },
            "&:hover svg": {
              fill: "#B12011",
            },
          },
          "&.Mui-disabled svg": {
            fill: "#E6E9EF",
            background: "#E6E9EF",
            borderRadius: "50%",
          },
        },
      },
      // defaultProps: {
      //   checkedIcon: <RadioSelectedIcon />,
      // },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#323338",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.200691)",
          borderRadius: 4,
          fontSize: "0.875rem",
          fontWeight: 400,
          lineHeight: "22px",
          padding: "8px 16px",
        },
        arrow: {
          color: "#323338",
        },
      },
      defaultProps: {
        placement: "top",
        arrow: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            transform: "none",
            position: "relative",
            color: "#525C76",
            fontSize: "1.1rem",
            "&.Mui-focused": {
              color: "#525C76",
            },
            paddingBottom: "4px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiInputBase-input": {
            padding: "11.5px 14px",
            background: "#FFFFFF",
            border: "1px solid #DDDDDD",
            borderRadius: 3,
            "&::placeholder": {
              color: "#B2B7C2",
              fontSize: "1rem",
            },
            "&:focus": {
              border: "1px solid #525C76",
              "&::placeholder": {
                color: "#525C76",
              },
            },
          },
          "& .MuiInputBase-colorSuccess": {
            "& .MuiInputBase-input": {
              border: "1px solid #01B652",
            },
            "& + .MuiFormHelperText-root": {
              color: "#01B652",
              "& svg": {
                fill: "#01B652",
              },
            },
          },
          "& .MuiInputBase-colorWarning": {
            "& .MuiInputBase-input": {
              border: "1px solid #ED1B06",
            },
            "& + .MuiFormHelperText-root": {
              color: "#ED1B06",
              "& svg": {
                fill: "#ED1B06",
              },
            },
          },
          "& .MuiInputBase-colorError": {
            "& .MuiInputBase-input": {
              border: "1px solid #ED1B06",
            },
            "& + .MuiFormHelperText-root": {
              color: "#ED1B06",
              "& svg": {
                fill: "#ED1B06",
              },
            },
          },
          "& .MuiFormHelperText-root": {
            marginLeft: 0,
            fontSize: "0.75rem",
            "& svg": {
              fontSize: "0.875rem",
              verticalAlign: "text-bottom",
              marginRight: 8,
            },
          },
        },
      },
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          boxShadow: "none",
          textTransform: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:active": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          border: "1px solid #fd7685",
          "&:hover": {
            backgroundColor: "#B3005E",
            border: "1px solid #B3005E",
          },
          "&:active": {
            backgroundColor: "#B12011",
            border: "1px solid #B12011",
          },
          "&.Mui-disabled": {
            border: "1px solid #E90064",
            backgroundColor: "#E90064",
            color: "rgba(255,255,255)",
            opacity: 0.8,
          },
        },
        containedSecondary: {
          border: "1px solid #EFEFEF",
          backgroundColor: "#f7f7f7",
          color: "#767676",
          "&:hover": {
            backgroundColor: "rgba(237, 27, 6, 0.3)",
            border: "1px solid #ED1B06",
            color: "#ED1B06",
          },
          "&:active": {
            backgroundColor: "rgba(198, 34, 18, 0.3)",
            border: "1px solid #B12011",
            color: "#B12011",
          },
          "&.Mui-disabled": {
            border: "1px solid #EFEFEF",
            backgroundColor: "rgba(239, 239, 239, 0.5)",
            color: "#767676",
            opacity: 0.5,
          },
        },
        textInherit: {
          "&:hover": {
            color: "#ED1B06",
            backgroundColor: "transparent",
          },
          "&:active": {
            backgroundColor: "transparent",
            color: "#B12011",
          },
          "&.Mui-disabled": {
            color: "#767676",
          },
        },
        sizeLarge: {
          padding: "5px 30px 2px",
        },
        sizeMedium: {
          padding: "3px 28px",
          fontSize: "0.875rem",
          lineHeight: 1.75,
        },
        sizeSmall: {
          padding: "3px 23px 1px",
          fontSize: "0.75rem",
          lineHeight: 1.4,
        },
      },
    },
  },
};

export default themeConfig;
