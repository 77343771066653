import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Navigation = () => (
  <Box sx={{ mt: 1 }}>
    <Typography variant="body2" sx={{ fontWeight: 500 }}>
      Don’t have an account?{" "}
      <Button
        component={Link}
        to="/signup"
        sx={{
          p: 0,
          minWidth: 0,
          fontSize: "1rem",
          verticalAlign: "baseline",
        }}
      >
        Sign Up
      </Button>
    </Typography>
  </Box>
);

export default Navigation;
