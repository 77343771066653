import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReduxUser, loadUser } from "../auth/awsAurh";
import { loginLoading, loginSuccess } from "../Redux/Login/action";

const useAppInitializer = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    if (isLoading || user) {
      return;
    }
    setIsLoading(true);
    dispatch(loginLoading());
    loadUser()
      .then((data) => {
        if (!data) {
          return null;
        }
        const { user, attributes } = data;
        if (user) {
          dispatch(loginSuccess(getReduxUser(user, attributes)));
        }
        return null;
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }, [dispatch, isLoading, user]);
};

export default useAppInitializer;
