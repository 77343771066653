import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import makeApiCall from "../../../apis";
import { OrderCard } from "../../../Components/userProfile/OrderCard";
import { ProgressIndicator } from "../../../loading/ProgressIndicator";

import Wrapper from "../../wrapper/Wrapper";
import Empty from "./Empty";
import { VerticalStepper } from "./VerticalStepper";

const Orders = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOrderProductsData();
  }, []);

  const getOrderProductsData = () => {
    setLoading(true);
    makeApiCall(`/payment/orders/all?status=Continue`, {
      method: "GET",
    })
      .then((res) => {
        setData([...res.order]);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <Wrapper active={4}>
      <Box>
        <Typography variant="h3">Orders</Typography>
        <Box sx={{ width: "100%" }}>
          {loading ? (
            <ProgressIndicator />
          ) : (
            <Box>
              {data.length === 0 ? <Empty /> : null}
              {data.map((order) => (
                <Box
                  sx={{
                    p: 4,
                    pt: 2,
                    mt: 4,
                    mb: 8,
                    border: "1px solid #e9e9ed",
                    borderRadius: "4px",
                    position: "relative",
                  }}
                >
                  <Box sx={{ borderBottom: "1px solid #e9e9ed", pb: 1 }}>
                    <Typography variant="h3">{order.username}</Typography>
                    <Typography sx={{ mt: 1 }} variant="body1">
                      {`Address: ${order.address}`}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      mt: 2,
                      flexDirection: { xs: "column", md: "row" },
                      justifyContent: "space-between",
                    }}
                  >
                    <VerticalStepper
                      status={order.deliveryStatus}
                      date={order.date}
                      orderId={order._id}
                      getOrderProductsData={getOrderProductsData}
                    />

                    <Box>
                      {order.orderItem.map((item, index) => (
                        <OrderCard key={item._id} data={item} index={index} />
                      ))}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      justifyContent: "space-between",
                      alignItems: "end",
                      gap: 3,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{ mt: 2 }}
                      >{`Amount Pay: ₹${Intl.NumberFormat("en-IN").format(
                        order.payable
                      )}`}</Typography>
                      <Typography
                        variant="body1"
                        sx={{ mt: 2 }}
                      >{`Payment Mode: ${order.paymentMethod}`}</Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};

export default Orders;
