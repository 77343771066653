import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWishlistProductsData } from "../../Redux/Wishlist/action";
import { Box } from "@mui/material";
import LoadingIndicator from "../../loading/LoadingIndicator";
import Empty from "./Empty";
import { WishlistProductCard } from "./WishlistProductCard";

export const WishlistPage = () => {
  const dispatch = useDispatch();
  const { loading, wishlistProducts } = useSelector(
    (state) => state.wishlistProducts
  );

  const { userId } = useSelector((state) => state.user);

  useEffect(() => {
    document.title = "Wishlist | Appka Fashion shopping platform";
  }, [dispatch]);

  useEffect(() => {
    dispatch(getWishlistProductsData());
  }, [dispatch]);

  if (userId === "") {
    return <Empty show={true} />;
  }

  return (
    <Box>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Box>
          {wishlistProducts.length === 0 ? (
            <Empty show={false} />
          ) : (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "48% 48%",
                  md: "30% 30% 30%",
                  lg: "24% 24% 24% 24%",
                },
                gap: 2,
                justifyContent: "space-between",
                mt: 8,
              }}
            >
              {wishlistProducts.map((e) => (
                <WishlistProductCard
                  key={e._id}
                  product={e.productId}
                  wishId={e._id}
                />
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
