import { createStore, combineReducers, applyMiddleware } from "redux";

import thunk from "redux-thunk";
import { addressReducer } from "./Address/reducer";
import { cartProductsReducer } from "./Cart/reducer";
import { categoryReducer, designerReducer } from "./categoryDesigner/reducer";
import { userReducer } from "./Login/reducer";

import { productsReducer } from "./Products/reducer";
import { filterOptionReducer } from "./subMenuOption/reducer";
import { wishlistProductsReducer } from "./Wishlist/reducer";
import { orderProductsReducer } from "./YourOrder/reducer";
import { couponReducer } from "./coupon/reducer";

export const rootReducer = combineReducers({
  products: productsReducer,
  cartProducts: cartProductsReducer,
  user: userReducer,
  address: addressReducer,
  orderProducts: orderProductsReducer,
  wishlistProducts: wishlistProductsReducer,
  category: categoryReducer,
  designer: designerReducer,
  filterOption: filterOptionReducer,
  coupon: couponReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
