import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { selectBodySizeChart, selectProductSizeChart } from "../utils";
import { Close } from "@mui/icons-material";

const SizeChartDialog = ({
  open,
  handleClose,
  bodySizeChart,
  proSizeChart,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ position: "relative" }}>
        <Typography variant="h4">Size Guide</Typography>
        <IconButton
          sx={{ position: "absolute", top: 5, right: 5 }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        {proSizeChart && (
          <Box
            component="img"
            src={selectProductSizeChart(proSizeChart)}
            sx={{ width: "100%" }}
          />
        )}
        {bodySizeChart && (
          <Box
            component="img"
            src={selectBodySizeChart(bodySizeChart)}
            sx={{ width: "100%" }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SizeChartDialog;
