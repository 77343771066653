import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Wrapper from "../wrapper/Wrapper";
import { DeleteOutlineOutlined, Close } from "@mui/icons-material";
import makeApiCall from "../../apis";
import { useEffect } from "react";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";

const Dashboard = () => {
  const [admin, setAdmin] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    role: "admin",
  });

  const handleFieldChange = (e) => {
    setFormData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };

  const getRegister = (event) => {
    event.preventDefault();
    makeApiCall(`/signup`, {
      method: "POST",
      body: JSON.stringify(formData),
    })
      .then((res) => {
        showSuccessNotification("Successfully Admin Added");
        getUser();
        setOpen(false);
        setFormData({
          name: "",
          email: "",
          mobile: "",
          password: "",
          role: "admin",
        });
      })
      .catch((error) => showErrorNotification(error.message));
  };

  const getUser = () => {
    makeApiCall(`/admin`, {
      method: "GET",
    })
      .then((res) => {
        setAdmin([...res.user]);
      })
      .catch((error) => showErrorNotification(error.message));
  };

  const adminDelete = (id) => {
    makeApiCall(`/admin/delete/${id}`, {
      method: "DELETE",
    })
      .then((res) => {
        showSuccessNotification("Successfully Deleted");
        getUser();
      })
      .catch((error) => showErrorNotification(error.message));
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const isButtonDisabled =
    !formData.name || !formData.email || !formData.mobile || !formData.password;
  console.log(admin);
  return (
    <Wrapper active={1}>
      <Box sx={{ width: "70%", m: "auto" }}>
        <Box>
          {admin.map((user, index) => (
            <Box
              sx={{
                display: "flex",
                gap: 3,
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #dddddd",
                p: 2,
                mt: 2,
              }}
            >
              <Typography variant="body2">{index + 1}</Typography>
              <Typography variant="body2">{user.name}</Typography>
              <Typography variant="body2">{user.email}</Typography>
              <Typography variant="body2">{user.mobile}</Typography>
              <IconButton
                onClick={() => {
                  adminDelete(user._id);
                }}
                sx={{ fontSize: "16px", color: "red" }}
              >
                <DeleteOutlineOutlined />
              </IconButton>
            </Box>
          ))}
          <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
            <Button onClick={handleOpen}>+Add Admin</Button>
          </Box>
        </Box>

        <Dialog open={open} onClose={handleClose} maxWidth="md">
          <form onSubmit={getRegister}>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                color: (theme) => theme.palette.primary.main,
              }}
            >
              <Typography variant="h3">Add Admin</Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 10, pt: 5 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0 auto",
                  gap: 3,
                  mt: 3,
                }}
              >
                <TextField
                  value={formData.name}
                  name="name"
                  placeholder="Full Name"
                  onChange={handleFieldChange}
                  fullWidth
                />

                <TextField
                  value={formData.email}
                  name="email"
                  placeholder="Email"
                  onChange={handleFieldChange}
                  type="email"
                  fullWidth
                />

                <TextField
                  value={formData.mobile}
                  name="mobile"
                  placeholder="Mobile"
                  onChange={handleFieldChange}
                  type="number"
                  fullWidth
                />

                <TextField
                  value={formData.password}
                  name="password"
                  placeholder="Password"
                  type="text"
                  onChange={handleFieldChange}
                  fullWidth
                />
                <Button
                  disabled={isButtonDisabled}
                  variant="contained"
                  type="submit"
                  size="large"
                  fullWidth
                  sx={{ p: "10px 30px 6px", borderRadius: 10 }}
                >
                  Signup
                </Button>
              </Box>
            </DialogContent>
          </form>
        </Dialog>
      </Box>
    </Wrapper>
  );
};

export default Dashboard;
