import { Box, Typography } from "@mui/material";
import { topSectionImg } from "./constants";

const TopSection = () => {
  return (
    <Box sx={{ display: "flex", mt: 1, justifyContent: "space-between" }}>
      {topSectionImg.map((item) => (
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "6%",
          }}
        >
          <img style={{ width: "100%" }} src={item.image} alt="image" />
          <p
            style={{
              fontSize: 12,
              marginTop: -6,
              fontWeight: 500,
              padding: 0,
              textAlign: "center",
            }}
          >
            {item.title}
          </p>
        </Box>
      ))}
    </Box>
  );
};

export default TopSection;
