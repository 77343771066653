import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeApiCall from "../../apis";
import { ProgressIndicator } from "../../loading/ProgressIndicator";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import { getOrderProductsData } from "../../Redux/YourOrder/action";
import { OrderCard } from "./OrderCard";
import { VerticalStepper } from "./VerticalStepper";
import Wrapper from "./wrapper/Wrapper";

const OrdersReturns = () => {
  const dispatch = useDispatch();
  const { orderProducts, loading } = useSelector(
    (state) => state.orderProducts
  );

  useEffect(() => {
    dispatch(getOrderProductsData());
  }, [dispatch]);

  const handleCancelOrderRemove = (orderId, date, status) => {
    if (diffHours(new Date(date), new Date()) >= 24 && status === "cancel") {
      makeApiCall(`/payment/orders/delete/${orderId}`, {
        method: "DELETE",
      })
        .then(() => {
          dispatch(getOrderProductsData());
        })
        .catch((error) => console.error(error));
    }
  };

  const handleCancelOrder = (orderId) => {
    makeApiCall(`/payment/orders/cancel/${orderId}`, {
      method: "PUT",
    })
      .then((res) => {
        showSuccessNotification("Order Canceled");
        dispatch(getOrderProductsData());
      })

      .catch((error) => showErrorNotification(error.message));
  };

  const diffHours = (dt2, dt1) => {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  };

  return (
    <Wrapper activeColor="1">
      <Box>
        <Typography variant="h3">Your Orders</Typography>
        <Box sx={{ width: "100%" }}>
          {loading ? (
            <ProgressIndicator />
          ) : (
            <Box>
              {orderProducts.map((order) => (
                <Box
                  sx={{
                    p: 4,
                    pt: 2,
                    mt: 4,
                    mb: 8,
                    border: "1px solid #e9e9ed",
                    borderRadius: "4px",
                  }}
                >
                  <Box sx={{ borderBottom: "1px solid #e9e9ed", pb: 1 }}>
                    <Typography variant="h3">{order.username}</Typography>
                    <Typography sx={{ mt: 1 }} variant="body1">
                      {`Address: ${order.address}`}
                    </Typography>
                  </Box>

                  {handleCancelOrderRemove(
                    order._id,
                    order.updatedAt,
                    order.status
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      mt: 2,
                      flexDirection: { xs: "column", md: "row" },
                      justifyContent: "space-between",
                    }}
                  >
                    <VerticalStepper
                      status={order.deliveryStatus}
                      date={order.date}
                    />

                    <Box>
                      {order.orderItem.map((item, index) => (
                        <OrderCard key={item._id} data={item} index={index} />
                      ))}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      justifyContent: "space-between",
                      alignItems: "end",
                      gap: 3,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{ mt: 2 }}
                      >{`Amount Pay: ₹${Intl.NumberFormat("en-IN").format(
                        order.payable
                      )}`}</Typography>
                      <Typography
                        variant="body1"
                        sx={{ mt: 2 }}
                      >{`Payment Mode: ${order.paymentMethod}`}</Typography>
                    </Box>
                    {diffHours(new Date(order.date), new Date()) < 24 ? (
                      order.status === "Cancel" ? (
                        <Typography
                          variant="h3"
                          sx={{ color: (theme) => theme.palette.primary.main }}
                        >
                          Order Canceled
                        </Typography>
                      ) : (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            handleCancelOrder(order._id);
                          }}
                        >
                          Order Cancel
                        </Button>
                      )
                    ) : null}
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};

export default OrdersReturns;
