import "./ProductDetails.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeApiCall from "../../apis";
import { Box, Button, Divider, Typography } from "@mui/material";
import { AddShoppingCart, Favorite, FavoriteBorder } from "@mui/icons-material";
import { useChecker } from "../card/useChecker";
import { apisCall } from "../../apis/apis";
import { ProductsSlider } from "../sliders/Slider";
import ShippingCard from "./ShippingCard";
import { FiCopy } from "react-icons/fi";
import { ProgressIndicator } from "../../loading/ProgressIndicator";
import { priceFormat, useCurrencyConverter } from "../utils";
import ContactCard from "./ContactCard";
import SocialComponent from "../footer/SocialComponent";
import SizeChartDialog from "./SizeChartDialog";

const formatDate = (date) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Date(date).toLocaleDateString("en-US", options);
};

export const ProductDetailsPage = () => {
  const [size, setSize] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sizeSelect, setSizeSelect] = useState(null);
  const [selectClass, setSelectClass] = useState("");
  const [salePrice, setSalePrice] = useState();
  const [data, setData] = useState({});
  const [couponData, setCouponData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { userId } = useSelector((state) => state.user);
  const { currencyData, currency } = useCurrencyConverter();

  const { isCart, isWishlist } = useChecker(data._id);
  const { handleWishlistApiCall, handleCartApiCall } = apisCall(dispatch);

  useEffect(() => {
    setSalePrice(data.discountedPrice);
  }, [id, data]);

  useEffect(() => {
    findData();
    getCouponData();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const findData = () => {
    setLoading(true);
    makeApiCall(`/products/${id}`, {
      method: "GET",
    })
      .then((res) => {
        setData({ ...res.product });
        setSize([...res.product.size]);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const cartHandle = () => {
    if (sizeSelect) {
      handleCartApiCall(data._id, sizeSelect, data.discount, salePrice);
    } else {
      setShow(true);
    }
  };

  const wishlistHandle = () => {
    handleWishlistApiCall(data._id);
  };

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(couponData[0]?.couponCode);
    setCopied(true);
  };

  const handleSize = (size, price) => {
    setSizeSelect(size);
    price ? setSalePrice(price) : setSalePrice(data.discountedPrice);
  };

  const getCouponData = () => {
    makeApiCall(`/coupon`, {
      method: "GET",
    })
      .then((res) => {
        setCouponData(res.coupon);
      })
      .catch((error) => console.log(error));
  };

  const shippingDate = (shippingTime) => {
    const currentDate = new Date();
    const futureDate = new Date(
      currentDate.getTime() + shippingTime * 24 * 60 * 60 * 1000
    );
    return formatDate(futureDate);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  if (loading)
    return (
      <Box minHeight={500}>
        <ProgressIndicator />
      </Box>
    );

  return (
    <Box sx={{ width: { xs: "95%", md: "85%" }, m: "auto", mt: 5 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: 4, md: 10 },
        }}
      >
        <Box flex={5}>
          <Box sx={{ width: "100%" }}>
            <img
              style={{ width: "100%" }}
              src={data.thumbnail}
              alt={data.title}
            />
          </Box>
        </Box>
        <Box flex={4} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h3">{`${data.designer}-${data.title}`}</Typography>
          <Typography variant="body2">{data.description}</Typography>
          <Divider />
          <Box>
            <Box sx={{ display: "flex", gap: 1, mt: 1, alignItems: "center" }}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Typography variant="h3">{`${priceFormat(
                  salePrice,
                  currencyData,
                  currency
                )}`}</Typography>

                {data.discount && data.discount > 0 ? (
                  <Typography
                    variant="body2"
                    sx={{
                      textDecoration: "line-through",
                      fontWeight: "bold",
                      mt: 1,
                    }}
                  >
                    {`${priceFormat(data.netPrice, currencyData, currency)}`}
                  </Typography>
                ) : null}
              </Box>
              {data.discount && data.discount > 0 ? (
                <Typography
                  variant="body1"
                  sx={{ color: (theme) => theme.palette.primary.main }}
                >
                  ({data.discount}% OFF)
                </Typography>
              ) : null}
            </Box>

            <Typography variant="body3">(inclusive of all taxes)</Typography>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h5">Select Your Size</Typography>
              {data?.bodySizeChart && data?.sizeChart && (
                <Button
                  variant="text"
                  sx={{ p: 0, px: 1, fontWeight: 600, color: "#e62e72" }}
                  onClick={handleOpen}
                >
                  Size Guide
                </Button>
              )}
            </Box>
            <Box className="product_size">
              {size.map((e, i) => (
                <Box
                  key={i}
                  className={`single_size_class ${
                    selectClass === i ? "active" : ""
                  }`}
                  sx={{ textAlign: "center", p: 0.5, minWidth: "40px" }}
                  eachBox={selectClass}
                  onClick={() => {
                    setShow(false);
                    setSelectClass(i);
                    handleSize(e.value, e.price);
                  }}
                >
                  <Typography variant="body3" sx={{ lineHeight: 1 }}>
                    {e.value}
                    <br />
                    {e.price && (
                      <Typography
                        component="span"
                        variant="body3"
                        color="primary"
                        sx={{ p: 0, mt: "0px", fontSize: "12px" }}
                      >{`${priceFormat(
                        e.price,
                        currencyData,
                        currency
                      )}`}</Typography>
                    )}
                  </Typography>
                </Box>
              ))}
            </Box>

            {show ? (
              <Typography variant="body3" style={{ color: "red" }}>
                Please select product size
              </Typography>
            ) : null}

            <Box sx={{ mt: 1 }}>
              <Typography variant="body3">
                <span style={{ color: "#000000", fontWeight: 600 }}>
                  Shipping:
                </span>{" "}
                {` The estimate shipping date for this product is by ${shippingDate(
                  data.shippingTime
                )}. Please note that, this is shipping date and not the delivery date.`}
              </Typography>
            </Box>

            <Box sx={{ mt: 1 }}>
              <Typography variant="body3">
                Note : Body measurements include 1 to 2 inches of loosening.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 4,
              mt: 2,
            }}
          >
            <Button
              sx={{ p: 1, pl: 3, pr: 3, borderRadius: 0 }}
              onClick={() => {
                userId === "" || !userId ? navigate("/signin") : cartHandle();
              }}
              disabled={isCart}
              variant="contained"
              size="large"
              startIcon={<AddShoppingCart />}
            >
              {isCart ? "ALREADY ADDED" : "ADD TO CART"}
            </Button>

            <Button
              variant="contained"
              size="large"
              sx={{
                p: 1,
                pl: 3,
                pr: 3,
                bgcolor: "#3D1766",
                borderRadius: 0,
                borderColor: "#3D1766",
                "&.Mui-disabled": {
                  bgcolor: "#3D1766",
                  borderColor: "#3D1766",
                },

                color: isWishlist
                  ? (theme) => theme.palette.primary.main
                  : "#ffffff",
              }}
              onClick={() => {
                userId === "" || !userId
                  ? navigate("/signin")
                  : wishlistHandle();
              }}
              disabled={isWishlist}
              startIcon={isWishlist ? <Favorite /> : <FavoriteBorder />}
            >
              WISHLIST
            </Button>
          </Box>
          {couponData[0]?.couponCode ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                mt: 2,
              }}
            >
              <Typography variant="h5">OFFER FOR YOU</Typography>
              <Box mt={2}>
                <Typography
                  style={{
                    border: "1px dotted #c1c1c1",
                    padding: "4px 8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    maxWidth: 220,
                  }}
                >
                  <span
                    style={{
                      fontSize: 18,
                    }}
                  >
                    Coupon:
                  </span>
                  <span
                    style={{
                      fontSize: 18,
                      color: "#e62e72",
                    }}
                  >
                    {` ${couponData[0]?.couponCode || ""}`}
                  </span>
                  <button
                    onClick={handleCopy}
                    style={{
                      color: "gray",
                      fontSize: 22,
                      border: "none",
                      backgroundColor: "transparent",
                      marginLeft: 4,
                    }}
                  >
                    <FiCopy />
                  </button>
                </Typography>
              </Box>
              {copied && <Typography variant="body2">Copied!</Typography>}
            </Box>
          ) : null}

          <ShippingCard />
          <Divider />
          <ContactCard />

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
            <SocialComponent />
          </Box>

          <Box sx={{ mt: 4 }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 600, borderBottom: "3px solid #D8D9CF" }}
            >
              ABOUT
            </Typography>

            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 2 }}
            >
              {data.meta?.map((item) => (
                <Typography variant="body3">{`${item.key}: ${item.value}`}</Typography>
              ))}
              <Typography variant="body3">100% Original Products</Typography>
              <Typography variant="body3">
                Pay on delivery might be available
              </Typography>
              <Typography variant="body3">
                Easy 30 days returns and exchanges
              </Typography>
              <Typography variant="body3">
                Try & Buy might be available
              </Typography>
              <Typography variant="body3">
                Disclaimer: Colour of the actual product may vary from the image
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 10 }}>
        <Typography variant="h4">SIMILAR PRODUCTS</Typography>
      </Box>
      <ProductsSlider type="" />
      <SizeChartDialog
        open={openDialog}
        handleClose={handleClose}
        bodySizeChart={data.bodySizeChart}
        proSizeChart={data.sizeChart}
      />
    </Box>
  );
};
