import { Button, Divider, TextField, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTotal } from "./useTotal";
import { priceFormat, useCurrencyConverter } from "../utils";
import { useEffect, useState } from "react";
import makeApiCall from "../../apis";
import { useDispatch, useSelector } from "react-redux";
import { getCouponSuccess } from "../../Redux/coupon/action";

export const TotalAmount = ({ btnText, redirectLink, isActive, isShow }) => {
  const navigate = useNavigate();
  const { currencyData, currency } = useCurrencyConverter();
  const [couponValue, setCouponValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [couponVerifyData, setCouponVerifyData] = useState({});
  const couponState = useSelector((state) => state.coupon);
  const dispatch = useDispatch();

  useEffect(() => {
    if (couponState) {
      setCouponValue(couponState.coupon);
    }
  }, [couponState, couponVerifyData?.isCorrect]);

  const {
    total: { amount, netAmount, save, totalItem },
  } = useTotal();

  const handleFieldChange = (event) => {
    setCouponValue(event.target.value);
  };

  const getCouponVerify = () => {
    makeApiCall(`/coupon/verify`, {
      method: "POST",
      body: JSON.stringify({
        couponCode: couponValue,
        price: amount,
      }),
    })
      .then((res) => {
        setCouponVerifyData(res);
        if (res.message) {
          setErrorMsg(res.message);
        }
        if (res.isCorrect) {
          dispatch(
            getCouponSuccess({
              coupon: couponValue,
              discount: res.coupon.discount,
            })
          );
          setErrorMsg("");
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "row", md: "column", lg: "row" },
          gap: 1,
        }}
      >
        <Typography variant="h4">PRICE DETAILS </Typography>
        <Typography
          variant="body3"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {`(${totalItem} items)`}
        </Typography>
      </Box>
      <Divider />
      <Box>
        <Typography variant="body2">Coupon</Typography>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <input
            value={couponValue}
            placeholder="Enter"
            onChange={handleFieldChange}
            style={{
              width: "100%",
              padding: "8px 10px",
              fontSize: "16px",
              border: "1px solid gray",
              borderRadius: "5px",
            }}
          />
          <Button
            variant="text"
            sx={{ p: 0 }}
            color="primary"
            onClick={getCouponVerify}
          >
            Apply
          </Button>
        </Box>
        {errorMsg && (
          <Typography variant="body3" sx={{ color: "red" }}>
            {errorMsg}
          </Typography>
        )}
      </Box>
      <Divider />
      <Box sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}>
        <Typography variant="body2">Total MRP:</Typography>
        <Typography variant="body2">
          {priceFormat(amount, currencyData, currency)}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}>
        <Typography variant="body2">Net Amount:</Typography>
        <Typography variant="body2">
          {priceFormat(netAmount, currencyData, currency)}
        </Typography>
      </Box>
      {save > 0 ? (
        <Box sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}>
          <Typography variant="body2">Your Save:</Typography>
          <Typography variant="body2" color="primary" sx={{ fontWeight: 600 }}>
            {priceFormat(save, currencyData, currency)}
          </Typography>
        </Box>
      ) : null}
      <Divider />
      <Box sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}>
        <Typography variant="h5">Total Amount:</Typography>
        <Typography variant="h5">
          {priceFormat(amount, currencyData, currency)}
        </Typography>
      </Box>
      {isShow ? (
        <Button
          variant="contained"
          sx={{ borderRadius: 0, width: "100%", mt: 3 }}
          size="large"
          onClick={() => {
            navigate(redirectLink);
          }}
          disabled={isActive}
        >
          {btnText}
        </Button>
      ) : null}
    </Box>
  );
};
