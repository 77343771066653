import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  getReduxUser,
  loadUser,
  updateUserAttributes,
} from "../../auth/awsAurh";
import LoadingIndicator from "../../loading/LoadingIndicator";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import { loginLoading, loginSuccess } from "../../Redux/Login/action";
import Wrapper from "./wrapper/Wrapper";

const UserProfile = () => {
  const { user, loading } = useSelector((state) => state.user);
  const [active, setActive] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    gender: "",
    DOB: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setFormData({
      name: user ? user["custom:name"] : "",
      mobile: user && user["custom:mobile"] ? user["custom:mobile"] : "",
      gender: user && user["custom:gender"] ? user["custom:gender"] : "male",
      DOB: user && user["custom:DOB"] ? user["custom:DOB"] : "",
    });
  }, [user]);

  const handleFieldChange = (e) => {
    setFormData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };

  const handleActive = () => {
    setActive(!active);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const attributes = [];
    for (let key in formData) {
      attributes.push({ Name: `custom:${key}`, Value: formData[key] });
    }
    return updateUserAttributes(attributes);
  };

  const updateUserAttributesMutation = useMutation(handleSubmit, {
    onSuccess: () => {
      dispatch(loginLoading());
      setTimeout(() => {
        loadUser().then((data) => {
          if (!data) {
            return null;
          }
          const { user, attributes } = data;
          if (user) {
            dispatch(loginSuccess(getReduxUser(user, attributes)));
          }
          return null;
        });
      }, 500);
      showSuccessNotification("User Details Updated");
    },
    onError: (err) => {
      showErrorNotification(err.message);
    },
  });

  const isFormDisabled =
    !formData.name ||
    !formData.mobile ||
    updateUserAttributesMutation.isLoading;

  return (
    <Wrapper activeColor="2">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          mt: { xs: 3, sm: 3, md: 0, lg: 0 },
        }}
      >
        <Typography variant="h4">Account Details</Typography>
        <Typography variant="h2" fontWeight={400}>
          Hello,{" "}
          <span style={{ fontWeight: 600 }}>
            {user ? user["custom:name"] : ""}
          </span>
        </Typography>
        <form
          onSubmit={updateUserAttributesMutation.mutate}
          style={{ display: "flex", flexDirection: "column", gap: 20 }}
        >
          {updateUserAttributesMutation.isLoading && (
            <LoadingIndicator message="Updating..." />
          )}
          <Box>
            <Typography variant="body1">Email</Typography>
            <TextField
              value={user?.email}
              placeholder="E-mail"
              disabled
              sx={{ width: { xs: "100%", sm: "100%", md: "55%", lg: "55%" } }}
            />
          </Box>
          <Box>
            <Typography variant="body1">Name</Typography>
            <TextField
              value={formData.name}
              name="name"
              placeholder="Name"
              onChange={handleFieldChange}
              disabled={!active}
              sx={{ width: { xs: "100%", sm: "100%", md: "55%", lg: "55%" } }}
            />
          </Box>
          <Box>
            <Typography variant="body1">Mobile</Typography>
            <TextField
              value={formData.mobile}
              name="mobile"
              placeholder="Mobile"
              type="number"
              onChange={handleFieldChange}
              disabled={!active}
              sx={{ width: { xs: "100%", sm: "100%", md: "55%", lg: "55%" } }}
            />
          </Box>
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <RadioGroup
              row
              value={formData.gender}
              onChange={handleFieldChange}
              name="gender"
            >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>

          <Box>
            <Typography variant="body1">Date Of Birth</Typography>
            <TextField
              value={formData.DOB}
              name="DOB"
              type="date"
              onChange={handleFieldChange}
              disabled={!active}
            />
          </Box>

          {active ? (
            <Box display="flex" gap={4}>
              <Button variant="outlined" onClick={handleActive}>
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ color: "#ffffff" }}
                type="submit"
                disabled={isFormDisabled}
              >
                {loading ? `Savings...` : `Save`}
              </Button>
            </Box>
          ) : (
            <Box>
              <Button variant="outlined" onClick={handleActive}>
                Edit Profile
              </Button>
            </Box>
          )}
        </form>
      </Box>
    </Wrapper>
  );
};

export default UserProfile;
