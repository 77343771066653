import "./App.css";
import { Route, Routes } from "react-router-dom";
import AppLayout from "./AppLayout";
import {
  Cart,
  CheckoutAddess,
  Home,
  OrdersReturns,
  OrderThanksPage,
  Payment,
  ProductDetailsPage,
  ProductsPage,
  ShippingAddress,
  UserProfile,
  WishlistPage,
} from "./pages";
import {
  AddProduct,
  AddProductCategory,
  CouponManage,
  Dashboard,
  EditProduct,
  Orders,
  OrdersCanceled,
  OrdersDelivered,
} from "./admin";
import AuthPage from "./pages/Auth";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./ProtectedRoute";
import AdminProtectedRoute from "./AdminProtectedRoute";
import {
  ContactUsPage,
  PolicyPage,
  ReturnsAndExchanges,
} from "./Components/aboutUs";
import PurchaseOrderPolicy from "./Components/aboutUs/PurchaseOrderPolicy";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<AuthPage />} />
        <Route path="/signin" element={<AuthPage />} />
        <Route path="/forgot-password" element={<AuthPage />} />
        <Route path="/reset-password" element={<AuthPage />} />
        <Route path="/products/:type" element={<ProductsPage />} />
        <Route
          path="/products/category/:type/:category"
          element={<ProductsPage />}
        />
        <Route
          path="/products/designer/:type/:designer"
          element={<ProductsPage />}
        />
        <Route
          path="/product/:url_title/:id"
          element={<ProductDetailsPage />}
        />
        <Route
          path="/cart"
          element={
            <ProtectedRoute>
              <Cart />
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkout-address"
          element={
            <ProtectedRoute>
              <CheckoutAddess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          }
        />
        <Route path="/order-confirm" element={<OrderThanksPage />} />
        <Route
          path="/orders&returns"
          element={
            <ProtectedRoute>
              <OrdersReturns />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-profile"
          element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/address"
          element={
            <ProtectedRoute>
              <ShippingAddress />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wishlist"
          element={
            <ProtectedRoute>
              <WishlistPage />
            </ProtectedRoute>
          }
        />
        <Route path="/returns-exchanges" element={<ReturnsAndExchanges />} />
        <Route
          path="/purchase-order-policy"
          element={<PurchaseOrderPolicy />}
        />
        <Route path="/policy/:policy" element={<PolicyPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
      </Route>
      <Route>
        <Route
          path="/dashboard"
          element={
            <AdminProtectedRoute>
              <Dashboard />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/dashboard/coupon"
          element={
            <AdminProtectedRoute>
              <CouponManage />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/dashboard/add-product"
          element={
            <AdminProtectedRoute>
              <AddProduct />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/dashboard/order"
          element={
            <AdminProtectedRoute>
              <Orders />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/dashboard/order-delivered"
          element={
            <AdminProtectedRoute>
              <OrdersDelivered />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/dashboard/order-cancel"
          element={
            <AdminProtectedRoute>
              <OrdersCanceled />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/dashboard/edit-product"
          element={
            <AdminProtectedRoute>
              <EditProduct />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/dashboard/category-designer"
          element={
            <AdminProtectedRoute>
              <AddProductCategory />
            </AdminProtectedRoute>
          }
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
