import { Box } from "@mui/material";
import { purchaseOrderPolicy } from "./constent";

const HTMLContent = ({ htmlString }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

const PurchaseOrderPolicy = () => {
  return (
    <Box sx={{ width: { xs: "90%", sm: "70%", md: "60%" }, m: "auto" }}>
      <HTMLContent htmlString={purchaseOrderPolicy} />
    </Box>
  );
};

export default PurchaseOrderPolicy;
