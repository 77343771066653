import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

export const useTotal = () => {
  const { cartProducts } = useSelector((state) => state.cartProducts);
  const couponState = useSelector((state) => state.coupon);

  const total = useMemo(() => {
    let amount = 0;
    let netAmount = 0;
    let save = 0;
    let totalItem = 0;

    cartProducts.map((item) => {
      amount += item.price * item.quantity;
      totalItem += item.quantity;
      netAmount += item.netPrice * item.quantity;
    });

    save = netAmount - amount;

    if (couponState.discount > 0) {
      save = save + (amount * couponState.discount) / 100;
      amount = amount - (amount * couponState.discount) / 100;
    }

    return { amount, netAmount, save, totalItem };
  }, [cartProducts, couponState]);

  return {
    total,
  };
};
