import { CognitoUser } from "amazon-cognito-identity-js";
import { COGNITO_USER_POOL } from "./constants";

export const sendForgotPasswordCode = (email) =>
  new Promise((resolve, reject) => {
    if (!email) {
      reject(new Error("Invalid e-mail"));
      return;
    }
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: COGNITO_USER_POOL,
    });
    cognitoUser.forgotPassword({
      onSuccess(data) {
        // successfully initiated reset password request
        console.log(`CodeDeliveryData from forgotPassword`, data);
        resolve(true);
      },
      onFailure(err) {
        console.error("error while sending forgot password code", err.message);
        reject(err);
      },
    });
  });

export const resetPasswordByCode = (email, newPassword, verificationCode) =>
  new Promise((resolve, reject) => {
    if (!email) {
      reject(new Error("Invalid user name"));
      return;
    }
    if (!newPassword) {
      reject(new Error("Invalid password"));
      return;
    }
    if (!verificationCode) {
      reject(new Error("Invalid verification code"));
      return;
    }
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: COGNITO_USER_POOL,
    });
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        console.log(`resetPasswordByCode: success`);
        resolve(true);
      },
      onFailure(err) {
        console.error("error while sending resetting password", err.message);
        reject(err);
      },
    });
  });
