import { List, ListItemText } from "@mui/material";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilterOption } from "../../Redux/subMenuOption/action";
import { pages } from "./constants";
import LoadingSkeleton from "./skeleton/LoadingSkeleton";
import SubMenuOption from "./SubMenuOption";

const SubMenus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hover, setHover] = useState(null);
  const [subMenu, setSubMenu] = useState({
    category: [],
    designer: [],
  });

  const { allCategory, allDesigner, loading } = useSelector(
    (state) => state.filterOption
  );

  const handleNavigate = (route) => {
    navigate(`products/${route.toLowerCase()}`);
  };

  const onMouseOver = (type, index) => {
    dispatch(getFilterOption(type));
    setHover(index);
  };

  const onMouseLeave = () => {
    setHover(null);
  };

  useEffect(() => {
    setSubMenu({
      category: [...allCategory],
      designer: [...allDesigner],
    });
  }, [allCategory, allDesigner, loading]);

  return (
    <List
      sx={{
        p: 1,
        pl: { xs: 1, md: 2 },
        pr: { xs: 1, md: 2 },
        color: "#000000",
        display: "flex",
        fontSize: "15px",
        gap: 5,
        position: "relative",
      }}
      onMouseLeave={onMouseLeave}
    >
      {pages.map((page, ind) => (
        <>
          <li
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleNavigate(page);
            }}
            onMouseOver={() => onMouseOver(page.toLowerCase(), ind)}
          >
            <ListItemText primary={page} />
          </li>
          <Box
            sx={{
              display: ind === hover ? "block" : "none",
              m: "auto",
              position: "absolute",
              top: 40,
              width: "100%",
              bgcolor: "#ffffff",
              zIndex: 100,
              p: 4,
              left: 0,
              pl: 10,
            }}
            onMouseLeave={onMouseLeave}
          >
            {loading ? (
              <LoadingSkeleton subMenu={subMenu} />
            ) : (
              <SubMenuOption subMenu={subMenu} type={page} />
            )}
          </Box>
        </>
      ))}
    </List>
  );
};

export default SubMenus;
