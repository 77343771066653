const img1 = "./assets/home/New.png";
const img2 = "./assets/home/Cosmatic.png";
const img3 = "./assets/home/Decor.png";
const img4 = "./assets/home/Designer.png";
const img5 = "./assets/home/Kids.png";
const img6 = "./assets/home/KurtaSet.png";
const img7 = "./assets/home/Kurti.png";
const img8 = "./assets/home/Lehenga.png";
const img9 = "./assets/home/Men.png";
const img10 = "./assets/home/Accessories.png";
const img11 = "./assets/home/Readytoship.png";
const img12 = "./assets/home/Sarees.png";
const img13 = "./assets/home/Sherwani.png";
const img14 = "./assets/home/Wedding.png";
const img15 = "./assets/home/Women.png";

const bigImg1 = "./assets/home/bigImg1.jpg";
const bigImg2 = "./assets/home/bigImg2.jpg";
const bigImg3 = "./assets/home/bigImg3.jpg";

export const topSectionImg = [
  {
    image: img1,
    title: "New",
  },
  {
    image: img2,
    title: "Cosmatic",
  },
  {
    image: img3,
    title: "Decor",
  },
  {
    image: img4,
    title: "Designer",
  },
  {
    image: img5,
    title: "Kids",
  },
  {
    image: img6,
    title: "Kurta Set",
  },
  {
    image: img7,
    title: "Kurti",
  },
  {
    image: img8,
    title: "Lehenga",
  },
  {
    image: img9,
    title: "Men",
  },
  {
    image: img10,
    title: "Accessories",
  },
  {
    image: img11,
    title: "Readytoship",
  },
  {
    image: img12,
    title: "Sarees",
  },
  {
    image: img13,
    title: "Sherwani",
  },
  {
    image: img14,
    title: "Wedding",
  },
  {
    image: img15,
    title: "Women",
  },
];

export const bigCardData = [
  {
    title: "Tarun Tahiliani",
    image: bigImg1,
    description: "Grandeur with a semblance of elegance",
  },
  {
    title: "Torani",
    image: bigImg2,
    description: "Heritage velvets that exude timelessness",
  },
  {
    title: "Matsya",
    image: bigImg3,
    description: "A bespoke blend of classic & contemporary",
  },
];
