import { COUPON_SUCCESS } from "./action";

const initialState = {
  coupon: null,
  discount: 0,
};

export const couponReducer = (store = initialState, { type, payload }) => {
  switch (type) {
    case COUPON_SUCCESS:
      return {
        coupon: payload.coupon || null,
        discount: payload.discount || 0,
      };
    default:
      return store;
  }
};
