import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeApiCall from "../../apis";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import { getAddressData, getAddressSuccess } from "../../Redux/Address/action";

const Address = () => {
  const { userId, user } = useSelector((state) => state.user);
  const { address } = useSelector((state) => state.address);
  const dispatch = useDispatch();

  const [active, setActive] = useState(false);
  const [addressForm, setAddressForm] = useState({
    name: "",
    mobile: "",
    pincode: "",
    addressDetails: "",
    city: "",
    state: "",
    userId,
  });

  useEffect(() => {
    dispatch(getAddressData());
  }, [userId, dispatch]);

  useEffect(() => {
    setAddressForm({
      name: address?.name ? address.name : user && user["custom:name"],
      mobile: address?.mobile ? address.mobile : user && user["custom:mobile"],
      pincode: address?.pincode,
      addressDetails: address?.addressDetails,
      city: address?.city,
      state: address?.state,
      userId,
    });
  }, [address, userId]);

  const handleFieldChange = (e) => {
    setAddressForm((data) => ({ ...data, [e.target.name]: e.target.value }));
  };

  const handleActive = () => {
    setActive(!active);
  };

  const addressSubmit = () => {
    makeApiCall(`/address/update`, {
      method: "PATCH",
      body: JSON.stringify(addressForm),
    })
      .then((res) => {
        setActive(!active);
        dispatch(getAddressSuccess(res));
        showSuccessNotification("Successfully Address Updated");
      })

      .catch((error) => showErrorNotification(error.message));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        mt: { xs: 3, sm: 3, md: 0, lg: 0 },
      }}
    >
      <Typography variant="h4">Shipping Address</Typography>
      <Box>
        <Typography variant="body1">Name</Typography>
        <TextField
          value={addressForm.name}
          name="name"
          placeholder="Name"
          onChange={handleFieldChange}
          disabled={!active}
          sx={{ width: { xs: "100%", sm: "100%", md: "65%", lg: "65%" } }}
        />
      </Box>
      <Box>
        <Typography variant="body1">Mobile</Typography>
        <TextField
          value={addressForm.mobile}
          name="mobile"
          placeholder="Mobile"
          type="number"
          onChange={handleFieldChange}
          disabled={!active}
          maxLength={10}
          size={10}
          inputProps={{ maxLength: 10 }}
          sx={{ width: { xs: "100%", sm: "100%", md: "65%", lg: "65%" } }}
        />
      </Box>

      <Box>
        <Typography variant="body1">Address Details</Typography>
        <TextField
          value={addressForm.addressDetails}
          name="addressDetails"
          placeholder="addressDetails"
          onChange={handleFieldChange}
          disabled={!active}
          sx={{ width: { xs: "100%", sm: "100%", md: "65%", lg: "65%" } }}
        />
      </Box>

      <Box>
        <Typography variant="body1">City</Typography>
        <TextField
          value={addressForm.city}
          name="city"
          placeholder="city"
          onChange={handleFieldChange}
          disabled={!active}
          sx={{ width: { xs: "100%", sm: "100%", md: "65%", lg: "65%" } }}
        />
      </Box>

      <Box>
        <Typography variant="body1">State</Typography>
        <TextField
          value={addressForm.state}
          name="state"
          placeholder="state"
          onChange={handleFieldChange}
          disabled={!active}
          sx={{ width: { xs: "100%", sm: "100%", md: "65%", lg: "65%" } }}
        />
      </Box>

      <Box>
        <Typography variant="body1">PinCode</Typography>
        <TextField
          value={addressForm.pincode}
          name="pincode"
          placeholder="pincode"
          type="number"
          onChange={handleFieldChange}
          disabled={!active}
          sx={{ width: { xs: "100%", sm: "100%", md: "65%", lg: "65%" } }}
        />
      </Box>

      {active ? (
        <Box display="flex" gap={4}>
          <Button variant="outlined" onClick={handleActive}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ color: "#ffffff" }}
            onClick={addressSubmit}
          >
            Save
          </Button>
        </Box>
      ) : (
        <Box>
          <Button variant="outlined" onClick={handleActive}>
            Edit Address
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Address;
