import { PRODUCT_DESIGNER_SUCCESS, PRODUCT_CATEGORY_SUCCESS } from "./action";

const initialStateCate = {
  category: [],
};

const initialStateDesiner = {
  designer: [],
};

export const categoryReducer = (
  store = initialStateCate,
  { type, payload }
) => {
  switch (type) {
    case PRODUCT_CATEGORY_SUCCESS:
      return {
        ...store,
        category: [...payload],
      };

    default:
      return store;
  }
};

export const designerReducer = (
  store = initialStateDesiner,
  { type, payload }
) => {
  switch (type) {
    case PRODUCT_DESIGNER_SUCCESS:
      return {
        ...store,
        designer: [...payload],
      };
    default:
      return store;
  }
};
