import { bodySizeChartImage, productSizeChartImage } from "./constent";

export const selectBodySizeChart = (key) => {
  const res = bodySizeChartImage.find((item) => Object.keys(item)[0] === key);
  return res[key];
};

export const selectProductSizeChart = (key) => {
  const res = productSizeChartImage.find(
    (item) => Object.keys(item)[0] === key
  );
  return res[key];
};
