import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import MenuOption from "./MenuOption";
import { MenuOpen } from "@mui/icons-material";
import { useState } from "react";
import MenuItems from "./MenuItems";

const Wrapper = ({ children, activeColor }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleClose = () => {
    setAnchorElUser(null);
  };

  const handleOpen = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
        mt: 6,
      }}
    >
      <MenuOption
        activeColor={activeColor}
        style={{ display: { xs: "none", sm: "none", md: "flex" } }}
      />

      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "flex-end",
          cursor: "pointer",
        }}
      >
        <IconButton size="large" onClick={handleOpen}>
          <MenuOpen fontSize="inherit" />
        </IconButton>

        <Menu
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleClose}
        >
          <MenuItems handleClose={handleClose} />
        </Menu>
      </Box>

      <Box sx={{ flex: { sm: 1, md: 3, lg: 3 } }}>{children}</Box>
    </Box>
  );
};

export default Wrapper;
