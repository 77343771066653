import { getCurrentUserIdToken } from "../auth/awsAurh/token";
import { getApiUrl } from "./config";

const makeApiCall = async (url, params) => {
  const token = await getCurrentUserIdToken();
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  params.headers = headers;
  const response = await fetch(`${getApiUrl}${url}`, params);
  if (!response.ok) {
    let errorData = await response.json();
    return Promise.reject(errorData);
  }
  return response.json();
};

export default makeApiCall;
