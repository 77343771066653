import { Box, Typography } from "@mui/material";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const LoadingSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        mt: 5,
        m: "auto",
        width: "95%",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box
        sx={{
          bgcolor: { xs: "transparent", md: "#F0EEED" },
          p: { xs: 2, md: 5 },
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <SkeletonTheme color="#F3E8FF" highlightColor="#D2D3C9">
          <Typography variant="h4">
            <Skeleton height={40} />
          </Typography>
        </SkeletonTheme>
        <SkeletonTheme color="#F3E8FF" highlightColor="#D2D3C9">
          {Array(5)
            .fill()
            .map((_, index) => (
              <Typography key={index} variant="body">
                <Skeleton height={20} />
              </Typography>
            ))}
        </SkeletonTheme>
      </Box>

      <Box
        sx={{
          p: { xs: 2, md: 5 },
          maxHeight: { xs: "auto", md: 500 },
          display: "flex",
          flexDirection: "column",
          flexWrap: { xs: "nowrap", md: "wrap" },
          flexGrow: 2,
          gap: 3,
        }}
      >
        <Typography variant="h4">
          <Skeleton height={40} />
        </Typography>
        {Array(5)
          .fill()
          .map((_, index) => (
            <Typography key={index} variant="body">
              <Skeleton height={25} />
            </Typography>
          ))}
      </Box>
      <Box
        sx={{
          bgcolor: "#F0EEED",
          maxWidth: 300,
          p: { xs: 2, md: 5 },
          display: { xs: "none", md: "block" },
          flexGrow: 1,
        }}
      >
        <Skeleton height={300} width="100%" />
      </Box>
    </Box>
  );
};

export default LoadingSkeleton;
