export const occasionOption = [
  "Bride",
  "Mehendi & Haldi",
  "Sangeet",
  "Cocktail",
  "Wedding",
  "Reception",
  "Destination Wedding",
  "Resort",
  "Work",
  "Party",
  "Puja",
];

export const bodySizeChart = ["Women Body Chart", "Men Body Chart"];

export const productSizeChart = [
  "Women Lehenga",
  "Women Kurti",
  "Mens Sherwani",
  "Mens Nawabi",
  "Mens Jodhpuri Jacket",
  "Mens Kurta & Chudihar",
  "MENS Jacket",
  "MENS Jacket and kurta",
  "Mens Indo Western",
  "KIDS Jackets",
  "KIDS Kurta Pajama",
  "KIDS Sherwani",
];
