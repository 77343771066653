import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Empty = ({ show }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: { xs: "55%", md: "30%" },
        m: "auto",
        textAlign: "center",
        mt: 15,
        mb: 15,
      }}
    >
      <Box>
        <img style={{ width: "100%" }} src="empty-cart.png" alt="empty-cart" />
      </Box>

      {show ? (
        <Box sx={{ mt: 3 }}>
          <p>Please Login/Signup!</p>
          <Button
            sx={{ mt: 3 }}
            onClick={() => {
              navigate("/signin");
            }}
            variant="outlined"
            size="large"
          >
            LOGIN
          </Button>
        </Box>
      ) : (
        <Box sx={{ mt: 3 }}>
          <p>Empty Wishlist!</p>
          <Button
            sx={{ mt: 3 }}
            onClick={() => {
              navigate("/");
            }}
            variant="outlined"
            size="large"
          >
            ADD ITEMS
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Empty;
