import { Step, StepLabel, Stepper, Box, Typography } from "@mui/material";
import { addDays, format } from "date-fns";

const steps = [
  {
    label: "Prepare For Dispatch",
  },
  {
    label: "Ready For Dispatch",
  },
  {
    label: "Going For Delivery",
  },

  {
    label: "Ready For Product",
  },

  {
    label: "Product Delivered",
  },
];

export const VerticalStepper = ({ status, date }) => {
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={status.length - 1} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 4 ? (
                  <Typography variant="caption">Finished</Typography>
                ) : null
              }
            >
              {step.label}

              {index === 0 ? (
                <Typography variant="body2">
                  {format(new Date(date), "dd/MM/yyyy")}
                </Typography>
              ) : null}

              {index === 4 ? (
                <Typography variant="body2">
                  {format(addDays(new Date(date), 5), "dd/MM/yyyy")}
                </Typography>
              ) : null}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
