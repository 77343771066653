import { Box, TextField, Button, Slide, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../../loading/LoadingIndicator";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import { sendForgotPasswordCode } from "../awsAurh/password";
import Navigation from "./Navigation";

const ForgotPasswordForm = () => {
  const [forgotData, setForgotData] = useState({
    email: "",
  });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    return sendForgotPasswordCode(forgotData.email);
  };

  const handleFieldChange = (e) => {
    setForgotData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };

  const forgotPasswordMutation = useMutation(handleSubmit, {
    onSuccess: () => {
      showSuccessNotification(
        "Instructions to reset your password is sent. Please check your registered email."
      );
      return navigate("/reset-password");
    },
    onError: (err) => {
      showErrorNotification(err.message);
    },
  });

  const isButtonDisabled =
    !forgotData.email || forgotPasswordMutation.isLoading;
  return (
    <Slide direction="left" in>
      <Box>
        <form onSubmit={forgotPasswordMutation.mutate}>
          {forgotPasswordMutation.isLoading && (
            <LoadingIndicator message="Sending password reset instructions..." />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 375,
              margin: "0 auto",
              gap: 5,
            }}
          >
            <Typography variant="body1">
              Please enter your e-mail you’d like your password reset
            </Typography>
            <TextField
              value={forgotData.userName}
              name="email"
              placeholder="E-mail"
              onChange={handleFieldChange}
              fullWidth
            />

            <Button
              disabled={isButtonDisabled}
              variant="contained"
              type="submit"
              size="large"
              fullWidth
              sx={{ p: "10px 30px 6px", borderRadius: 10 }}
            >
              Reset Password
            </Button>
          </Box>
        </form>
        <Navigation />
      </Box>
    </Slide>
  );
};

export default ForgotPasswordForm;
