import { Close, SearchOutlined } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import makeApiCall from "../../apis";
import { ProgressIndicator } from "../../loading/ProgressIndicator";
import { ProductCard } from "../card/ProductCard";

const SearchDialog = ({ openDialog, setOpenDialog }) => {
  const [input, setInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const handleInput = (event) => {
    setInput(event.target.value);
  };

  useEffect(() => {
    setData([]);
    setInput(null);
  }, [openDialog]);

  const handleSearchApi = () => {
    setLoading(true);
    makeApiCall(`/products/search?search=${input}`, {
      method: "GET",
    })
      .then((res) => {
        setData([...res.products]);
        setLoading(false);
      })
      .catch((error) => console(error));
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={openDialog}
      sx={{ p: 0 }}
      fullWidth={true}
      maxWidth="lg"
      PaperProps={{
        style: {
          minHeight: "90%",
          maxHeight: "90%",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center", position: "relative" }}>
        <Typography variant="h3">Search Products</Typography>
        <Box sx={{ width: "100%", mt: 2 }}>
          <TextField
            placeholder="Search for designers, products and categories"
            sx={{ width: { xs: "100%", md: "80%" }, m: "auto" }}
            fullWidth
            value={input}
            onChange={handleInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    sx={{ bgcolor: "#EDEDED" }}
                    onClick={handleSearchApi}
                  >
                    <SearchOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 1, top: 1 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "100%",
              sm: "48% 48%",
              md: "24% 24% 24% 24%",
            },
            justifyContent: "space-between",
            m: "auto",
            mt: 5,
            overflow: "hidden",
          }}
        >
          {loading ? (
            <ProgressIndicator />
          ) : (
            data.map((item) => (
              <Box key={item._id} onClick={handleClose}>
                <ProductCard item={item} />
              </Box>
            ))
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SearchDialog;
