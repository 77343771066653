import { Box } from "@mui/material";

const NotFound = () => (
  <Box sx={{ mt: 10, textAlign: "center", p: 3 }}>
    <h1>404 - Page Not Found</h1>
    <p>
      Sorry, the page you are looking for does not exist. Please return to our{" "}
      <a style={{ color: "blue" }} href="/">
        Homepage
      </a>
      .
    </p>
  </Box>
);

export default NotFound;
