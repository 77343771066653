import { CognitoUserPool } from "amazon-cognito-identity-js";

export const REACT_APP_COGNITO_REGION = "us-east-1";
export const REACT_APP_COGNITO_USER_POOL_ID = "us-east-1_v9muqI0iH";
export const REACT_APP_COGNITO_CLIENT_ID = "6t4m5n15s8s2kdcvhlutmn4c2f";
export const REACT_APP_COGNITO_IDENTITY_POOL_ID =
  "us-east-1:3e308487-9e7e-4ae4-a966-93e466378f2d";

export const DEFAULT_PASSWORD = "welcome@123";
if (
  !REACT_APP_COGNITO_CLIENT_ID ||
  !REACT_APP_COGNITO_USER_POOL_ID ||
  !REACT_APP_COGNITO_REGION
) {
  throw new Error("Missing cognito credentials");
}
const poolData = {
  UserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: REACT_APP_COGNITO_CLIENT_ID,
};

export const COGNITO_USER_POOL = new CognitoUserPool(poolData);
