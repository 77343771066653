import makeApiCall from ".";
import { getCurrentUserToken } from "../auth/token";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../notification/Notification";
import { getCartProductsData } from "../Redux/Cart/action";
import { getWishlistProductsData } from "../Redux/Wishlist/action";

export const apisCall = (dispatch) => {
  const { userId } = getCurrentUserToken();

  const handleWishlistApiCall = (productId) => {
    makeApiCall(`/wishlist`, {
      method: "POST",
      body: JSON.stringify({ productId, userId }),
    })
      .then((res) => {
        dispatch(getWishlistProductsData());
        showSuccessNotification("Successfully Added");
      })
      .catch((error) => showErrorNotification(error.message));
  };

  const handleCartApiCall = (productId, size, discount, salePrice) => {
    let sPrice = salePrice;
    if (discount > 0) sPrice = salePrice - (salePrice * discount) / 100;
    makeApiCall(`/cart `, {
      method: "PUT",
      body: JSON.stringify({
        productId,
        quantity: 1,
        size,
        price: sPrice,
        netPrice: salePrice,
      }),
    })
      .then((res) => {
        dispatch(getCartProductsData());
        showSuccessNotification("Successfully Added");
      })
      .catch((error) => showErrorNotification(error.message));
  };

  return { handleWishlistApiCall, handleCartApiCall };
};
