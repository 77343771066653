import { useLocation } from "react-router-dom";
import AuthWrapper from "../auth/AuthWrapper";
import ForgotPasswordForm from "../auth/forgotPassword/ForgotPasswordForm";
import ResetPasswordForm from "../auth/resetPassword/ResetPasswordForm";
import SigninForm from "../auth/signin/SigninForm";
import SignupForm from "../auth/signup/Signup";

const AuthComponentsConfig = {
  signin: {
    title: "Sign In",
    headline: "Signin",
    form: <SigninForm />,
  },
  signup: {
    title: "Sign Up",
    headline: "Signup",
    form: <SignupForm />,
  },
  "forgot-password": {
    title: "Forgot Password",
    headline: "Forgot your password?",
    form: <ForgotPasswordForm />,
  },
  "reset-password": {
    title: "Reset Password",
    headline: "Reset your password",
    form: <ResetPasswordForm />,
  },
};
const AuthPage = () => {
  const params = useLocation();
  const pageId = params.pathname.replace("/", "");
  const config = AuthComponentsConfig[pageId];
  return (
    <AuthWrapper headline={config.headline} title={config.title}>
      {config.form}
    </AuthWrapper>
  );
};

export default AuthPage;
