import { Backdrop } from "@mui/material";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import { getApiUrl } from "../../apis/config";
import axios from "axios";
import "./style.css";
import { ProgressIndicator } from "../../loading/ProgressIndicator";
import ProductForm from "./ProductForm";

const EditForm = ({ productData }) => {
  const [loading, setLoading] = useState(false);

  const [size, setSize] = useState([
    {
      value: "",
      price: "",
    },
  ]);
  const [meta, setMeta] = useState([
    {
      key: "",
      value: "",
    },
  ]);
  const [data, setData] = useState({
    title: "",
    category: "",
    description: "",
    subcategory: "",
    type: "",
    designer: "",
    thumbnail: "",
    netPrice: "",
    discountedPrice: "",
    discount: 0,
    shippingTime: "",
    occasion: "",
    color: "",
    size: [],
    meta: [],
    isActive: true,
    bodySizeChart: "",
    sizeChart: "",
  });

  useEffect(() => {
    setData({
      title: productData.title,
      category: productData.category,
      description: productData.description,
      subcategory: productData.subcategory,
      type: productData.type,
      designer: productData.designer,
      thumbnail: productData.thumbnail,
      netPrice: productData.netPrice,
      discountedPrice: productData.discountedPrice,
      discount: productData.discount,
      shippingTime: productData.shippingTime,
      occasion: productData.occasion,
      color: productData.color,
      size: productData.size,
      meta: productData.meta,
      isActive: productData?.isActive,
      bodySizeChart: productData?.bodySizeChart,
      sizeChart: productData?.sizeChart,
    });
    setSize([
      ...productData.size,
      {
        value: "",
        price: "",
      },
    ]);
    setMeta([
      ...productData.meta,
      {
        key: "",
        value: "",
      },
    ]);
  }, [productData]);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const handleChangeSize = (event, index) => {
    const { name, value } = event.target;

    const newSize = size[index];
    newSize[name] = value;
    size[index] = newSize;

    const newSizeData = size.filter((s) => s.value !== "");
    setData((prev) => ({ ...prev, size: newSizeData }));

    if (size.length - 1 === index) {
      setSize((prev) => [...prev, { value: "", price: "" }]);
    }
  };

  const handleRemoveSize = (index) => {
    const newSizeData = size.filter(
      (s, inx) => inx !== index && s.value !== ""
    );
    setData((prev) => ({ ...prev, size: newSizeData }));
    setSize([...newSizeData, { value: "", price: "" }]);
  };

  const handleChangeMeta = (event, index) => {
    const { name, value } = event.target;
    const newMeta = meta[index];
    newMeta[name] = value;
    meta[index] = newMeta;

    const newMetaData = meta.filter((m) => m.value !== "" && m.key !== "");
    setData((prev) => ({ ...prev, meta: newMetaData }));

    if (meta.length - 1 === index) {
      setMeta((prev) => [...prev, { key: "", value: "" }]);
    }
  };

  const handleRemoveMeta = (index) => {
    const newMetaData = meta.filter((s, inx) => inx !== index && s.key !== "");
    setData((prev) => ({ ...prev, meta: newMetaData }));
    setMeta([...newMetaData, { key: "", value: "" }]);
  };

  const handleImage = (event) => {
    setData((data) => ({
      ...data,
      thumbnail: event.target.files[0],
    }));
  };

  useEffect(() => {
    if (data.netPrice) {
      let totalValue = (
        data.netPrice -
        data.netPrice * (data.discount / 100)
      ).toFixed(2);
      setData((prev) => ({ ...prev, ["discountedPrice"]: totalValue }));
    } else {
      setData((prev) => ({ ...prev, ["discountedPrice"]: "" }));
    }
  }, [data.discount, data.netPrice]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const url = `${getApiUrl}/products/update/${productData._id}`;

    try {
      await axios({
        method: "patch",
        url: url,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(() => {
        showSuccessNotification("Successfully Updated");
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      showErrorNotification(error.message);
    }
  };

  return (
    <Box>
      <Backdrop sx={{ color: "#fff", zIndex: 100 }} open={loading}>
        <ProgressIndicator />
      </Backdrop>
      <ProductForm
        handleSubmit={handleSubmit}
        data={data}
        loading={loading}
        handleChangeSize={handleChangeSize}
        handleImage={handleImage}
        handleFieldChange={handleFieldChange}
        handleRemoveSize={handleRemoveSize}
        size={size}
        meta={meta}
        handleChangeMeta={handleChangeMeta}
        handleRemoveMeta={handleRemoveMeta}
      />
    </Box>
  );
};

export default EditForm;
