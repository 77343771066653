import makeApiCall from "../../apis";

export const PRODUCTS_LOADING = "PRODUCTS_LOADING";
export const PRODUCTS_SUCCESS = "PRODUCTS_SUCCESS";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";

export const getProductsLoading = () => ({
  type: PRODUCTS_LOADING,
});

export const getProductsSuccess = (payload) => ({
  type: PRODUCTS_SUCCESS,
  payload,
});

export const getProductsError = () => ({
  type: PRODUCTS_ERROR,
});

export const getProductsData = (page, size, type) => (dispatch) => {
  dispatch(getProductsLoading());
  makeApiCall(`/products?page=${page}&limit=${size}&type=${type}`, {
    method: "GET",
  })
    .then((res) => {
      console.log(res);
      dispatch(getProductsSuccess(res));
    })
    .catch((error) => dispatch(getProductsError()));
};
