import {
  Box,
  TextField,
  Button,
  Slide,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import Navigation from "./Navigation";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import LoadingIndicator from "../../loading/LoadingIndicator";
import { resetPasswordByCode } from "../awsAurh/password";

const ResetPasswordForm = () => {
  const [passwordData, setPasswordData] = useState({
    newPassword: "",
    confirmPassword: "",
    email: "",
    otp: "",
  });
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    return resetPasswordByCode(
      passwordData.email,
      passwordData.newPassword,
      passwordData.otp
    );
  };

  const handleFieldChange = (e) => {
    setPasswordData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };

  const resetPasswordMutation = useMutation(handleSubmit, {
    onSuccess: () => {
      showSuccessNotification(
        "Your password is reset successfully. Please signin with new password"
      );
      return navigate("/signin");
    },
    onError: (err) => {
      showErrorNotification(err.message);
    },
  });

  const handleShowPassword = () => {
    setShow(!show);
  };

  const isResetDisabled =
    !passwordData.newPassword ||
    !passwordData.confirmPassword ||
    !passwordData.email ||
    !passwordData.otp ||
    resetPasswordMutation.isLoading;
  return (
    <Slide direction="left" in>
      <Box>
        <form onSubmit={resetPasswordMutation.mutate}>
          {resetPasswordMutation.isLoading && (
            <LoadingIndicator message="Resetting password..." />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 375,
              margin: "0 auto",
              gap: 5,
            }}
          >
            <TextField
              value={passwordData.email}
              name="email"
              placeholder="E-mail"
              onChange={handleFieldChange}
              fullWidth
            />
            <TextField
              value={passwordData.otp}
              name="otp"
              placeholder="OTP"
              onChange={handleFieldChange}
              fullWidth
            />
            <TextField
              value={passwordData.newPassword}
              name="newPassword"
              type={show ? "text" : "password"}
              placeholder="New password"
              onChange={handleFieldChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword}>
                      {show ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              value={passwordData.confirmPassword}
              name="confirmPassword"
              type={show ? "text" : "password"}
              placeholder="Confirm password"
              onChange={handleFieldChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword}>
                      {show ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              disabled={isResetDisabled}
              variant="contained"
              type="submit"
              size="large"
              fullWidth
              sx={{ p: "10px 30px 6px", borderRadius: 10 }}
            >
              Reset Password
            </Button>
          </Box>
        </form>
        <Navigation />
      </Box>
    </Slide>
  );
};

export default ResetPasswordForm;
