import { Box, Typography } from "@mui/material";

const Empty = () => {
  return (
    <Box sx={{ textAlign: "center", mt: 10 }}>
      <Typography>Empty Product</Typography>
    </Box>
  );
};

export default Empty;
