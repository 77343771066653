import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { APP_TITLE } from "./constant";

const AuthWrapper = ({ children, title, headline }) => (
  <Container>
    <Helmet>
      <title>
        {title} | {APP_TITLE}
      </title>
    </Helmet>
    {/* <Box
      sx={{
        textAlign: { xs: "center", sm: "left" },
        position: "fixed",
        left: 0,
        top: 0,
        width: "100vw",
        "& img": {
          ml: 2,
          mt: 2,
        },
      }}
    >
      <img src="/logo.png" alt={APP_TITLE} />
    </Box> */}
    <Box
      sx={{
        // maxWidth: 700,
        m: "0 auto",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        pt: "68px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          whiteSpace: "nowrap",
          position: "relative",
          mt: 3,
          mb: 3,
          justifyContent: "center",
        }}
      >
        <Typography variant="h3" color="primary">
          {headline}
        </Typography>
      </Box>
      {children}
    </Box>
  </Container>
);

export default AuthWrapper;
