import {
  Divider,
  Box,
  AppBar,
  Typography,
  IconButton,
  TextField,
  Toolbar,
  MenuItem,
  Menu,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import SubMenus from "./SubMenus";
import { useEffect, useState } from "react";
import MenuDrawer from "./MenuDrawer";
import { useDispatch, useSelector } from "react-redux";
import { getCartProductsData } from "../../Redux/Cart/action";
import { getWishlistProductsData } from "../../Redux/Wishlist/action";
import ActionButton from "./ActionButton";
import SearchDialog from "../searchPage/SearchDialog";
import Logo from "./logo/Logo";

const pages = ["Products", "Pricing", "Blog"];

const Header = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalItem, setTotalItem] = useState(0);
  const navigate = useNavigate();

  const { cartProducts } = useSelector((state) => state.cartProducts);

  useEffect(() => {
    dispatch(getCartProductsData());
    dispatch(getWishlistProductsData());
  }, [dispatch]);

  useEffect(() => {
    let qty = 0;
    cartProducts.map((item) => {
      qty += item.quantity;
    });
    setTotalItem(qty);
  }, [cartProducts]);

  const handleOpenDrawer = () => {
    setOpen(true);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    console.log("DDDDD");
  };

  console.log(open);

  return (
    <>
      <AppBar position="static" sx={{ bgcolor: "#ffffff" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: { md: 0, xs: 0 },
            width: "100%",
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
              pl: { xs: 1, md: 5 },
              pr: { xs: 1, md: 5 },
              gap: { md: 10, lg: 25 },
              height: "30px",
            }}
          >
            <IconButton
              size="large"
              onClick={handleOpenDrawer}
              sx={{
                display: { xs: "block", md: "none" },
                cursor: "pointer",
              }}
            >
              <MenuIcon />
            </IconButton>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <Menu
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                cursor: "pointer",
                maxWidth: "100px",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              <Logo />
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "block" },
                width: "100%",
                cursor: "pointer",
                position: "relative",
              }}
              onClick={handleOpenDialog}
            >
              <TextField
                placeholder="Search for designers, products and categories"
                fullWidth
                disabled
              />
              <Box
                sx={{
                  p: "4px 18px",
                  cursor: "pointer",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 100,
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>

            <ActionButton totalItem={totalItem} />
          </Toolbar>

          <Divider />

          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <SubMenus />
          </Box>
          <Box
            sx={{
              display: { xs: "block", md: "none" },
              p: "4px 18px",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={handleOpenDialog}
          >
            <TextField
              placeholder="Search for designers, products and categories"
              fullWidth
              disabled
              onClick={handleOpenDialog}
            />
            <Box
              sx={{
                p: "4px 18px",
                cursor: "pointer",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 100,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </Box>
      </AppBar>
      <MenuDrawer open={open} setOpen={setOpen} />
      <SearchDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
    </>
  );
};
export default Header;
