import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { priceFormat, productNavigator, useCurrencyConverter } from "../utils";
import { useNavigate } from "react-router-dom";

export const OrderCard = ({ data }) => {
  const { currencyData, currency } = useCurrencyConverter();
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        display: "flex",
        mt: 3,
        position: "relative",
        maxWidth: { xs: 1000, md: 550 },
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: 81, cursor: "pointer" }}
        image={data.productId.thumbnail}
        alt={data.productId.title}
        onClick={() => {
          productNavigator(data.productId, navigate);
        }}
      />
      <CardContent
        sx={{
          flex: "1 0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          component="div"
          variant="h5"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            productNavigator(data.productId, navigate);
          }}
        >
          {data.productId.title}
        </Typography>
        <Typography
          variant="body3"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: 400,
          }}
        >
          {data.productId.description}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: { xs: 1, md: 5 },
            alignItems: { xs: "start", md: "center" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
            <Typography variant="h5">Price:</Typography>
            <Typography variant="h5">
              {priceFormat(data.price, currencyData, currency)}
            </Typography>
          </Box>
          <Typography variant="h5">{`Size: ${data.size}`}</Typography>
          <Typography variant="h5">{`For: ${data.productId.type}`}</Typography>
        </Box>
        <Typography variant="body2">{`Quantity: ${data.quantity}`}</Typography>
      </CardContent>
    </Card>
  );
};
