import { useState } from "react";
import UserNameForm from "./userNameForm/UserNameForm";
import CompleteSignupForm from "./completeSignupForm/CompleteSignupForm";
import Navigation from "./Navigation";
import { Box, Slide } from "@mui/material";

const SignupForm = () => {
  const [signupData, setSignupData] = useState({
    user: null,
    otpSent: false,
  });

  const afterOtpSent = (user) => {
    setSignupData((data) => ({
      ...data,
      otpSent: true,
      user,
    }));
  };
  const getContent = () => {
    if (!signupData.otpSent || !signupData.user) {
      return <UserNameForm afterOtpSent={afterOtpSent} />;
    }

    return (
      <Slide direction="left" in>
        <Box>
          <CompleteSignupForm user={signupData.user} />
        </Box>
      </Slide>
    );
  };
  return (
    <Slide direction="left" in>
      <Box>
        {getContent()}
        <Navigation />
      </Box>
    </Slide>
  );
};
export default SignupForm;
