import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import Address from "../userProfile/Address";
import Wrapper from "./Wrapper";

const CheckoutAddess = () => {
  const { address } = useSelector((state) => state.address);

  const isActive =
    !address?.addressDetails ||
    !address?.city ||
    !address?.mobile ||
    !address?.name ||
    !address?.pincode ||
    !address?.state;

  return (
    <Wrapper
      btnText="PAYMENT"
      redirectLink="/payment"
      isActive={isActive}
      isShow
    >
      <Box sx={{ width: { xs: "100%", md: "70%" } }}>
        <Address />
      </Box>
    </Wrapper>
  );
};

export default CheckoutAddess;
