import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useChecker = (productId) => {
  const { cartProducts } = useSelector((state) => state.cartProducts);
  const { wishlistProducts } = useSelector((state) => state.wishlistProducts);

  return useMemo(() => {
    const isCart = cartProducts.some(
      (item) => item.productId._id === productId
    );

    const isWishlist = wishlistProducts.some(
      (item) => item.productId._id === productId
    );

    return { isCart, isWishlist };
  }, [productId]);
};
