import { Box, Button, TextField, Typography } from "@mui/material";
import { useMutation } from "react-query";
import { useState } from "react";
import { signupWithAttributes } from "../../awsAurh";
import { showErrorNotification } from "../../../notification/Notification";
import LoadingIndicator from "../../../loading/LoadingIndicator";

const UserNameForm = ({ afterOtpSent }) => {
  const [showPass, setShowPass] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    role: "user",
  });
  const handleFieldChange = (e) => {
    setFormData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    return signupWithAttributes(formData);
  };

  const signupMutation = useMutation(handleSubmit, {
    onSuccess: (user) => {
      console.info("send otp successful");
      afterOtpSent(user);
    },
    onError: (err) => {
      showErrorNotification(err.message);
    },
  });
  const isBtnDisabled =
    !formData.name ||
    !formData.email ||
    !formData.mobile ||
    !formData.password ||
    signupMutation.isLoading;

  return (
    <form onSubmit={signupMutation.mutate}>
      {signupMutation.isLoading && (
        <LoadingIndicator message="Sending OTP..." />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 375,
          margin: "0 auto",
          gap: 3,
        }}
      >
        <TextField
          type="text"
          placeholder="E-mail"
          value={formData.email}
          onChange={handleFieldChange}
          variant="outlined"
          name="email"
        />
        <TextField
          value={formData.name}
          name="name"
          placeholder="Full Name"
          onChange={handleFieldChange}
          fullWidth
        />

        <TextField
          value={formData.mobile}
          name="mobile"
          placeholder="Mobile"
          onChange={handleFieldChange}
          type="number"
          fullWidth
        />

        <TextField
          value={formData.password}
          name="password"
          placeholder="Password"
          type={showPass ? `password` : "text"}
          onChange={handleFieldChange}
          fullWidth
        />

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <input
            type="checkbox"
            onClick={() => {
              setShowPass(!showPass);
            }}
          />
          <Typography variant="body2">Show Password</Typography>
        </Box>

        <Typography variant="body3">
          By signing up you agree to receive emails regarding latest offers and
          designer collections.
        </Typography>
        <Button
          variant="contained"
          type="submit"
          size="large"
          disabled={isBtnDisabled}
          sx={{ p: "10px 30px 6px", borderRadius: 10 }}
        >
          Send OTP
        </Button>
      </Box>
    </form>
  );
};
export default UserNameForm;
