import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Wrapper from "../wrapper/Wrapper";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import makeApiCall from "../../apis";
import { useEffect } from "react";
import { showErrorNotification } from "../../notification/Notification";
import moment from "moment/moment";

const CouponManage = () => {
  const [loading, setLoading] = useState(false);
  const [couponData, setCouponData] = useState([]);
  const [formData, setFormData] = useState({
    couponCode: "",
    discount: "",
    minPrice: "",
    expiryDate: "",
  });

  const handleFieldChange = (e) => {
    setFormData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    makeApiCall(`/coupon/create`, {
      method: "POST",
      body: JSON.stringify(formData),
    })
      .then((res) => {
        setLoading(false);
        getCoupon();
        setFormData({
          couponCode: "",
          discount: "",
          minPrice: "",
          expiryDate: "",
        });
      })
      .catch((error) => showErrorNotification(error.message));
  };

  const getCoupon = () => {
    makeApiCall(`/coupon`, {
      method: "GET",
    })
      .then((res) => {
        setCouponData(res.coupon);
      })
      .catch((error) => showErrorNotification(error.message));
  };

  const handleCouponDelete = (id) => {
    makeApiCall(`/coupon/delete/${id}`, {
      method: "DELETE",
    })
      .then((res) => {
        getCoupon();
        console.log(res);
      })
      .catch((error) => showErrorNotification(error.message));
  };

  useEffect(() => {
    getCoupon();
  }, []);

  const isButtonDisabled =
    !formData.couponCode || !formData.discount || !formData.expiryDate;

  return (
    <Wrapper active={6}>
      <Box
        sx={{
          width: "80%",
          m: "auto",
          display: "flex",
          justifyContent: "space-between",
          gap: 3,
          flexDirection: { xs: "column", sm: "column", md: "row" },
        }}
      >
        <Box flex={1}>
          <TextField
            value={formData.couponCode}
            name="couponCode"
            placeholder="Coupon Code"
            onChange={handleFieldChange}
            fullWidth
          />
          <TextField
            value={formData.discount}
            name="discount"
            placeholder="Discount(%)"
            onChange={handleFieldChange}
            type="number"
            fullWidth
            sx={{ mt: 1 }}
          />
          <TextField
            value={formData.minPrice}
            name="minPrice"
            placeholder="Minimum Price"
            onChange={handleFieldChange}
            type="number"
            fullWidth
            sx={{ mt: 1 }}
          />
          <TextField
            value={formData.expiryDate}
            name="expiryDate"
            placeholder="Expiry Date"
            onChange={handleFieldChange}
            type="date"
            label="Expiry Date"
            fullWidth
            sx={{ mt: 1 }}
          />
          <Button
            disabled={isButtonDisabled}
            onClick={handleSubmit}
            variant="contained"
            sx={{
              p: "10px 30px 6px",
              mt: 2,
              borderRadius: 10,
              maxWidth: "250px",
              mt: 2,
            }}
          >
            {loading ? `Submitting...` : "Submit"}
          </Button>
        </Box>
        <Box flex={1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Typography variant="body2" flex={1}>
              Coupon Code
            </Typography>
            <Typography variant="body2" flex={1}>
              Discount(%)
            </Typography>
            <Typography variant="body2" flex={1}>
              Expiry Date
            </Typography>
            <Typography variant="body2" flex={1}>
              Minimum Price
            </Typography>
            <IconButton sx={{ color: "red", visibility: "hidden" }}>
              <DeleteOutlineOutlined />
            </IconButton>
          </Box>
          <Box sx={{ width: "100%" }}>
            {couponData &&
              couponData.map((item) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  <Typography variant="body2" flex={1}>
                    {item.couponCode}
                  </Typography>
                  <Typography variant="body2" flex={1}>
                    {item.discount}
                  </Typography>
                  <Typography variant="body2" flex={1}>
                    {moment(item.expiryDate).format("DD-MM-YYYY")}
                  </Typography>
                  <Typography variant="body2" flex={1}>
                    {item.minPrice}
                  </Typography>
                  <IconButton
                    sx={{ color: "red" }}
                    onClick={() => handleCouponDelete(item._id)}
                  >
                    <DeleteOutlineOutlined />
                  </IconButton>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default CouponManage;
