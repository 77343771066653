import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { occasionOption } from "./constants";

const FilterProducts = ({
  handleChecked,
  handlePriceRange,
  filterByPriceRange,
  filterType,
  priceRange,
  allCategory,
  allDesigner,
  allColor,
}) => {
  const categoriesCheck = (cate) => {
    const val = filterType.category.indexOf(cate);
    if (val === -1) return false;
    else return true;
  };

  const designerCheck = (des) => {
    const val = filterType.designer.indexOf(des);
    if (val === -1) return false;
    else return true;
  };

  const occasionCheck = (occ) => {
    const val = filterType.occasion.indexOf(occ);
    if (val === -1) return false;
    else return true;
  };

  const colorCheck = (cate) => {
    const val = filterType.color.indexOf(cate);
    if (val === -1) return false;
    else return true;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 6, mt: 2 }}>
      <Box>
        <Typography variant="h3">Categories</Typography>
        <Box
          sx={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <FormGroup>
            {allCategory.map((cate) => (
              <FormControlLabel
                sx={{ display: cate !== "" ? "flex" : "none" }}
                control={
                  <Checkbox
                    value={cate}
                    checked={categoriesCheck(cate)}
                    onChange={(event) => {
                      handleChecked(
                        event.target.value,
                        event.target.checked,
                        "category"
                      );
                    }}
                  />
                }
                label={cate}
              />
            ))}
          </FormGroup>
        </Box>
      </Box>

      <Box>
        <Typography variant="h3">Designer</Typography>
        <Box
          sx={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <FormGroup>
            {allDesigner.map((des) => (
              <FormControlLabel
                sx={{ display: des !== "" ? "flex" : "none" }}
                control={
                  <Checkbox
                    value={des}
                    checked={designerCheck(des)}
                    onChange={(event) => {
                      handleChecked(
                        event.target.value,
                        event.target.checked,
                        "designer"
                      );
                    }}
                  />
                }
                label={des}
              />
            ))}
          </FormGroup>
        </Box>
      </Box>

      <Box>
        <Typography variant="h3">Occasions</Typography>
        <Box
          sx={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <FormGroup>
            {occasionOption.map((occasion) => (
              <FormControlLabel
                sx={{ display: occasion !== "" ? "flex" : "none" }}
                control={
                  <Checkbox
                    value={occasion}
                    checked={occasionCheck(occasion)}
                    onChange={(event) => {
                      handleChecked(
                        event.target.value,
                        event.target.checked,
                        "occasion"
                      );
                    }}
                  />
                }
                label={occasion}
              />
            ))}
          </FormGroup>
        </Box>
      </Box>

      <Box>
        <Typography variant="h3">Colors</Typography>
        <Box
          sx={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <FormGroup>
            {allColor.map((cl) => (
              <FormControlLabel
                sx={{ display: cl !== "" ? "flex" : "none" }}
                control={
                  <Checkbox
                    value={cl}
                    checked={colorCheck(cl)}
                    onChange={(event) => {
                      handleChecked(
                        event.target.value,
                        event.target.checked,
                        "color"
                      );
                    }}
                  />
                }
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      fontSize: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        width: 15,
                        height: 14,
                        borderRadius: "50%",
                        bgcolor: cl,
                        border: "1px solid #F5F5F5",
                      }}
                    />
                    <span>{cl}</span>
                  </Box>
                }
              />
            ))}
          </FormGroup>
        </Box>
      </Box>
      <Box>
        <Typography variant="h3">Price Range</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "column", lg: "row" },
            gap: 2,
          }}
        >
          <TextField
            type="number"
            name="min"
            label="Min"
            variant="outlined"
            value={priceRange.min}
            onChange={handlePriceRange}
          />
          <TextField
            type="number"
            name="max"
            label="Max"
            variant="outlined"
            value={priceRange.max}
            onChange={handlePriceRange}
          />
        </Box>
        <Button onClick={filterByPriceRange}>Apply</Button>
      </Box>
    </Box>
  );
};

export default FilterProducts;
