export const policyMenu = [
  {
    title: "Privacy policy",
    route: "/policy/privacy-policy",
  },
  {
    title: "Terms And Conditions",
    route: "/policy/terms-and-conditions",
  },
  {
    title: "Cancellation and Refund",
    route: "/policy/cancellation-and-refund",
  },
  {
    title: "Shipping and Delivery",
    route: "/policy/shipping-and-delivery",
  },
  {
    title: "Contact Us",
    route: "/contact-us",
  },
];
