import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getCurrentUserIdToken } from "./auth/awsAurh/token";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { loginLoading } from "./Redux/Login/action";

const AdminProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.user);
  console.log("*****", user, loading);
  // useEffect(() => {
  //   dispatch(loginLoading);
  // }, [dispatch]);

  // if (loading) {
  //   return <CircularProgress />;
  // }

  // if (!user) {
  //   return <Navigate to="/signin" />;
  // }

  const getToken = async () => {
    const token = await getCurrentUserIdToken();
    return token;
  };

  return getToken() ? children : <Navigate to="/signin" />;
};

export default AdminProtectedRoute;
