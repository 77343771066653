import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ProductsSlider } from "../sliders/Slider";
import { BigCard } from "./BigCard";
import { Box, Typography } from "../../uiCore";
import TopSection from "./TopSection";
import { bigCardData } from "./constants";
import { Carousel } from "../sliders/Carousel";
import { Link } from "react-router-dom";

export const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Home | Appka Fashion shopping platform";
  }, []);

  return (
    <Box>
      <TopSection />
      <Carousel />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 10,
          gap: 3,
        }}
      >
        {bigCardData.map((item) => (
          <Link to={"/products/women"}>
            <BigCard item={item} />
          </Link>
        ))}
      </Box>

      <Box sx={{ mt: 6 }}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Exclusive collections to explore now
        </Typography>
        <ProductsSlider type="" />
      </Box>
    </Box>
  );
};
