import { Box, TextField } from "@mui/material";

const SizeComponent = ({ handleChangeSize, size, index, sx }) => {
  return (
    <Box sx={{ display: "flex", gap: 5, mt: 3, ...sx }}>
      <TextField
        fullWidth
        type="text"
        label="Size"
        onChange={(event) => {
          handleChangeSize(event, index);
        }}
        value={size.value}
        name="value"
      />

      <TextField
        fullWidth
        type="number"
        label="Price"
        onChange={(event) => {
          handleChangeSize(event, index);
        }}
        value={size.price}
        name="price"
      />
    </Box>
  );
};

export default SizeComponent;
