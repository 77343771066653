import makeApiCall from "../../apis";

export const PRODUCT_FILTER_OPTION_SUCCESS = "PRODUCT_FILTER_OPTION_SUCCESS";
export const PRODUCT_SUBMENU_OPTION_SUCCESS = "PRODUCT_SUBMENU_OPTION_SUCCESS";
export const PRODUCT_FILTER_OPTION_LOADING = "PRODUCT_FILTER_OPTION_LOADING";
export const PRODUCT_FILTER_OPTION_ERROR = "PRODUCT_FILTER_OPTION_ERROR";

export const getFilterOptionSuccess = (payload) => ({
  type: PRODUCT_FILTER_OPTION_SUCCESS,
  payload,
});
export const getFilterOptionLoading = () => ({
  type: PRODUCT_FILTER_OPTION_LOADING,
});

export const getFilterOptionError = () => ({
  type: PRODUCT_FILTER_OPTION_ERROR,
});

export const getAllSubMenuOptionSuccess = (payload) => ({
  type: PRODUCT_SUBMENU_OPTION_SUCCESS,
  payload,
});

export const getFilterOption = (type) => (dispatch) => {
  dispatch(getFilterOptionLoading());
  makeApiCall(`/products/filter-option?type=${type}`, {
    method: "GET",
  })
    .then((res) => {
      dispatch(getFilterOptionSuccess(res));
    })
    .catch((error) => {
      console.log(error.message);
      dispatch(getFilterOptionError());
    });
};

export const getAllSubMenuOption = () => (dispatch) => {
  makeApiCall(`/products/filter-option?type=new`, {
    method: "GET",
  })
    .then((res) => {
      dispatch(getAllSubMenuOptionSuccess(res));
    })
    .catch((error) => {
      console.log(error.message);
    });
};
