import {
  Step,
  StepLabel,
  Stepper,
  Box,
  Typography,
  StepContent,
  Button,
} from "@mui/material";
import { addDays, format } from "date-fns";
import makeApiCall from "../../../apis";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../notification/Notification";

const steps = [
  {
    label: "Prepare For Dispatch",
  },
  {
    label: "Ready For Dispatch",
  },
  {
    label: "Going For Delivery",
  },

  {
    label: "Ready For Product",
  },

  {
    label: "Product Delivered",
  },
];

export const VerticalStepper = ({
  status,
  date,
  orderId,
  getOrderProductsData,
}) => {
  const handleUpdateStatus = (text) => {
    makeApiCall(`/payment/orders/status/${orderId}`, {
      method: "PUT",
      body: JSON.stringify({ text }),
    })
      .then(() => {
        getOrderProductsData();
        showSuccessNotification("Status Updated");
      })
      .catch((error) => {
        console.error(error);
        showErrorNotification(error.message);
      });
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={status.length - 1} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 4 ? (
                  <Typography variant="caption">Finished</Typography>
                ) : null
              }
            >
              {step.label}

              {index === 0 ? (
                <Typography variant="body2">
                  {format(new Date(date), "dd/MM/yyyy")}
                </Typography>
              ) : null}

              {index === 4 ? (
                <Typography variant="body2">
                  {format(addDays(new Date(date), 5), "dd/MM/yyyy")}
                </Typography>
              ) : null}
            </StepLabel>
            <StepContent>
              <Box sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleUpdateStatus(step.label);
                  }}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {index === steps.length - 1 ? "Delivered" : "Update"}
                </Button>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
