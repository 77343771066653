import "./style.css";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { useEffect, useState } from "react";
import makeApiCall from "../../apis";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { getWishlistProductsData } from "../../Redux/Wishlist/action";
import { getCartProductsData } from "../../Redux/Cart/action";
import { priceFormat, productNavigator, useCurrencyConverter } from "../utils";

export const ProductCard = ({ item }) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const [size, setSize] = useState(null);
  const dispatch = useDispatch();
  const { cartProducts } = useSelector((state) => state.cartProducts);
  const { wishlistProducts } = useSelector((state) => state.wishlistProducts);
  const { userId } = useSelector((state) => state.user);
  const { currencyData, currency } = useCurrencyConverter();
  const [salePrice, setSalePrice] = useState();

  useEffect(() => {
    setSalePrice(item.discountedPrice);
  }, [item]);

  const checkCartItem = (cartItem) => {
    return cartProducts.some((item) => item.productId._id === cartItem);
  };

  const checkWishlistItem = (wishItem) => {
    return wishlistProducts.some((item) => item.productId._id === wishItem);
  };

  const handleHover = () => {
    setHover(true);
  };

  const handleLeave = () => {
    setHover(false);
  };

  const handleSize = (size, price) => {
    setSize(size);
    price ? setSalePrice(price) : setSalePrice(item.discountedPrice);
  };

  const handleWishlistApiCall = (productId) => {
    makeApiCall(`/wishlist`, {
      method: "POST",
      body: JSON.stringify({ productId }),
    })
      .then((res) => {
        dispatch(getWishlistProductsData());
        showSuccessNotification("Successfully Added");
      })
      .catch((error) => showErrorNotification(error.message));
  };

  const handleCartApiCall = (productId) => {
    let sPrice = salePrice;
    if (item.discount > 0)
      sPrice = salePrice - (salePrice * item.discount) / 100;
    makeApiCall(`/cart `, {
      method: "PUT",
      body: JSON.stringify({
        productId,
        quantity: 1,
        size,
        price: sPrice,
        netPrice: salePrice,
      }),
    })
      .then((res) => {
        dispatch(getCartProductsData());
        showSuccessNotification("Successfully Added");
      })
      .catch((error) => showErrorNotification(error.message));
  };

  return (
    <Box
      sx={{
        position: "relative",
        mb: 2,
        pb: { xs: 0, md: 0 },
        "&:hover": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        },
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: 0,
          pb: { xs: 0, md: 0 },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            width="100%"
            image={item.thumbnail}
            alt={item.title}
            sx={{ objectFit: "contain", cursor: "pointer" }}
            onClick={() => {
              productNavigator(item, navigate);
            }}
          />

          <Box
            className="show-size"
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              position: "absolute",
              bgcolor: "#ffffff",
              bottom: 0,
              p: 1,
              gap: 1,
              width: "100%",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                justifyContent: "center",
              }}
            >
              {item.size.map((s) => (
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      p: 0.5,
                      border: "1px solid #e9e9ed",
                      cursor: "pointer",
                      minWidth: "32px",
                      borderColor:
                        size === s.value
                          ? (theme) => theme.palette.primary.main
                          : "#e9e9ed",
                    }}
                    onClick={() => handleSize(s.value, s.price)}
                  >
                    {s.value}
                    <br />
                    {s.price && (
                      <Typography
                        component="span"
                        variant="body3"
                        color="primary"
                        sx={{ p: 0, mt: "0px", fontSize: "12px" }}
                      >{`${priceFormat(
                        s.price,
                        currencyData,
                        currency
                      )}`}</Typography>
                    )}
                  </Typography>
                </Box>
              ))}
            </Box>
            {checkCartItem(item._id) ? (
              <Button
                disabled
                variant="outlined"
                size="large"
                sx={{ color: (theme) => theme.palette.primary.main }}
              >
                Already Added
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="large"
                disabled={!size}
                onClick={() => {
                  userId === "" || !userId
                    ? navigate("/signin")
                    : handleCartApiCall(item._id);
                }}
              >
                Add To Cart
              </Button>
            )}
          </Box>
        </Box>

        <Box
          onClick={() => {
            productNavigator(item, navigate);
          }}
        >
          <CardContent
            sx={{
              p: 1,
              "&:last-child": {
                pb: 1,
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontSize: { xs: 14, sm: 16, md: 18 } }}
              className="ellipsis"
            >
              {`${item.designer}-${item.title}`}
            </Typography>
            <Typography
              variant="body3"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: { xs: 12, sm: 14, md: 16 },
              }}
            >
              {item.description}
            </Typography>
            <Box sx={{ display: "flex", gap: 1, mt: 1, pb: 0 }}>
              <Typography variant="h5">
                {priceFormat(item.discountedPrice, currencyData, currency)}
              </Typography>
              {item.discount ? (
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontSize: { xs: 12, sm: 14, md: 16 },
                  }}
                >
                  ({item.discount}% OFF)
                </Typography>
              ) : null}
            </Box>
          </CardContent>
        </Box>
      </Card>
      {checkWishlistItem(item._id) ? (
        <IconButton
          sx={{
            position: "absolute",
            top: 1,
            right: 1,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          <Favorite />
        </IconButton>
      ) : (
        <IconButton
          sx={{
            position: "absolute",
            top: 1,
            right: 1,
            color: (theme) => theme.palette.icon.main,
            "&:hover": {
              color: (theme) => theme.palette.primary.main,
            },
          }}
          onMouseOver={handleHover}
          onMouseLeave={handleLeave}
          onClick={() => {
            userId === "" || !userId
              ? navigate("/signin")
              : handleWishlistApiCall(item._id);
          }}
        >
          {hover ? <Favorite /> : <FavoriteBorder />}
        </IconButton>
      )}
    </Box>
  );
};
