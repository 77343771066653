import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import {
  DeleteOutlineOutlined,
  DriveFileRenameOutline,
} from "@mui/icons-material";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import makeApiCall from "../../apis";
import { useState } from "react";

const RowCard = ({ item, setActive, setEditData, handleUpdateSearchData }) => {
  const [open, setOpen] = useState(false);
  const handleDelete = () => {
    makeApiCall(`/products/delete/${item._id}`, {
      method: "DELETE",
    })
      .then(() => {
        showSuccessNotification("Successfully Deleted");
        handleUpdateSearchData(item._id);
        setOpen(false);
      })
      .catch((error) => showErrorNotification(error.message));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Card sx={{ display: "flex", mb: 2, position: "relative" }}>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={item.thumbnail}
        alt={item.title}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent
          sx={{
            flex: "1 0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Typography variant="h3">{item.title}</Typography>
          <Typography variant="body2">{item.description}</Typography>
          <Box sx={{ display: "flex", gap: 3 }}>
            <Typography variant="body2">Category: {item.category}</Typography>
            <Typography variant="body2">Designer: {item.designer}</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 3 }}>
            <Typography variant="body2">Price: ₹{item.price}</Typography>
            <Typography variant="body2">Discount: {item.discount}%</Typography>
          </Box>
        </CardContent>
      </Box>
      <Box
        sx={{ position: "absolute", display: "flex", gap: 2, top: 2, right: 5 }}
      >
        <IconButton
          sx={{ color: "red", bgcolor: "#EEEEEE" }}
          onClick={handleOpen}
        >
          <DeleteOutlineOutlined />
        </IconButton>
        <IconButton
          sx={{ color: "green", bgcolor: "#EEEEEE" }}
          onClick={() => {
            setActive(2);
            setEditData({ ...item });
          }}
        >
          <DriveFileRenameOutline />
        </IconButton>
      </Box>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ p: 5 }}
      >
        <DialogTitle>Are You Sure!</DialogTitle>
        <DialogContent dividers sx={{ p: 10, pt: 3 }}>
          <Box sx={{ display: "flex", gap: 8 }}>
            <Button onClick={handleDelete} variant="contained">
              Yes
            </Button>
            <Button onClick={handleClose} variant="outlined">
              No
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default RowCard;
