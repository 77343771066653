import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import makeApiCall from "../../apis";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import { getCartProductsData } from "../../Redux/Cart/action";
import { getOrderProductsData } from "../../Redux/YourOrder/action";
import { useTotal } from "./useTotal";
import { getCouponSuccess } from "../../Redux/coupon/action";

const COD = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shippingAddress, setShippingAddress] = useState("");
  const { user } = useSelector((state) => state.user);
  const { address } = useSelector((state) => state.address);
  const { cartProducts } = useSelector((state) => state.cartProducts);

  const {
    total: { amount },
  } = useTotal();

  useEffect(() => {
    setShippingAddress(
      `${address.name}, ${address.addressDetails}, ${address.city}, ${address.state}-${address.pincode}, Mob: ${address.mobile}`
    );
  }, [address]);

  const handleOrder = () => {
    makeApiCall(`/payment/orders/cod`, {
      method: "POST",
      body: JSON.stringify({
        orderItem: cartProducts,
        deliveryStatus: [{ text: "Order Confirm" }],
        payable: amount,
        paymentMethod: "COD",
        username: user ? user["custom:name"] : "",
        address: shippingAddress,
      }),
    })
      .then((res) => {
        console.log("order", res);
        dispatch(getCartProductsData());
        dispatch(getOrderProductsData());
        showSuccessNotification("Successfully Order Placed");
        navigate("/order-confirm");
        dispatch(
          getCouponSuccess({
            coupon: null,
            discount: 0,
          })
        );
      })
      .catch((error) => {
        showErrorNotification(error.message);
      });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5">
        COD(Cash On Delivery) not available on your location.
      </Typography>
      {/* <Typography variant="h5">Cash On Delivery (Cash/Card/UPI)</Typography> */}
      <Typography variant="body2" sx={{ mt: 2 }}>
        You can pay via Cash/Card or UPI enabled app at the time of delivery.
        Ask your delivery executive for these options.
      </Typography>
      {/* <Button variant="contained" sx={{ mt: 5 }} onClick={handleOrder}>
        PLACE ORDER
      </Button> */}
    </Box>
  );
};

export default COD;
