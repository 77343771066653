import { CheckBox, PermIdentityOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeApiCall from "../../apis";
import LoadingIndicator from "../../loading/LoadingIndicator";
import { ProgressIndicator } from "../../loading/ProgressIndicator";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import {
  getCartProductsData,
  getCartProductsLoading,
} from "../../Redux/Cart/action";
import { CartPageCard } from "./CartPageCard";
import EmptyCart from "./EmptyCart";
import Wrapper from "./Wrapper";

export const Cart = () => {
  const dispatch = useDispatch();

  const { cartProducts, loading } = useSelector((state) => state.cartProducts);

  useEffect(() => {
    dispatch(getCartProductsData());
  }, [dispatch]);

  useEffect(() => {
    cartProducts.sort(function (a, b) {
      if (a.productId < b.productId) {
        return -1;
      }
      if (a.productId > b.productId) {
        return 1;
      }
      return 0;
    });
  }, []);

  useEffect(() => {
    document.title = "Checkout | Appka Fashion shopping platform";
  }, []);

  const removeAllCartItems = () => {
    dispatch(getCartProductsLoading());
    makeApiCall(`/cart/delete-all `, {
      method: "DELETE",
    })
      .then((res) => {
        showSuccessNotification("Successfully Removed");
        dispatch(getCartProductsData());
      })
      .catch((error) => showErrorNotification(error.message));
  };

  if (loading) return <LoadingIndicator />;

  if (cartProducts.length === 0) return <EmptyCart />;

  return (
    <Wrapper btnText="CONTINUE" redirectLink="/checkout-address" isShow>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          flexDirection: "column",
          width: { xs: "100%", md: "70%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            p: 2,
            alignItems: "center",
            border: "1px solid #e9e9ed",
            borderRadius: "4px",
          }}
        >
          <img
            style={{ height: "70%" }}
            src="assets/cart/ship-free.png"
            alt="ship-free"
          />
          <Typography variant="body3">
            Yay! No convenience fee on this order.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            p: 2,
            border: "1px solid #e9e9ed",
            borderRadius: "4px",
          }}
        >
          <Box>
            <PermIdentityOutlined />
          </Box>
          <Box>
            <Typography variant="h5">Seller has changed</Typography>
            <Typography variant="body3">
              Seller of one or more items in your bag has changed. Please review
              them before proceeding.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <Typography
            variant="body3"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <CheckBox /> Items Selected
          </Typography>
          <Typography>- | -</Typography>
          <Button variant="text" onClick={removeAllCartItems}>
            ALL REMOVE
          </Button>
        </Box>
        {loading ? (
          <ProgressIndicator />
        ) : (
          <Box>
            {cartProducts.map((item, index) => (
              <CartPageCard key={item._id} data={item} index={index} />
            ))}
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};
