import "./style.css";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import { getCartProductsData } from "../../Redux/Cart/action";
import {
  getWishlistProductsData,
  getWishlistProductsError,
  getWishlistProductsLoading,
} from "../../Redux/Wishlist/action";
import makeApiCall from "../../apis";
import { useState } from "react";
import { priceFormat, productNavigator, useCurrencyConverter } from "../utils";

export const WishlistProductCard = ({ wishId, product }) => {
  const navigate = useNavigate();
  const { currencyData, currency } = useCurrencyConverter();
  const [size, setSize] = useState(null);
  const dispatch = useDispatch();
  const { cartProducts } = useSelector((state) => state.cartProducts);
  const checkCartItem = (cartItem) => {
    return cartProducts.some((item) => item.productId._id === cartItem);
  };

  const handleSize = (size) => {
    setSize(size);
  };

  const removeProduct = (wishId) => {
    dispatch(getWishlistProductsLoading());

    makeApiCall(`/wishlist/delete/${wishId}`, {
      method: "DELETE",
    })
      .then((res) => {
        showSuccessNotification("Successfully Removed");
        dispatch(getWishlistProductsData());
      })
      .catch((error) => {
        showErrorNotification(error.message);
        dispatch(getWishlistProductsError());
      });
  };

  const moveToCart = (id) => {
    makeApiCall(`/cart`, {
      method: "PUT",
      body: JSON.stringify({ productId: id, quantity: 1, size }),
    })
      .then(() => {
        showSuccessNotification("Successfully Added On Cart");
        dispatch(getCartProductsData());
      })
      .catch((error) => showErrorNotification(error.message));
  };

  return (
    <Box
      sx={{
        position: "relative",
        mb: 2,
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        "&:hover": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        },
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: 0,
        }}
      >
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            width="100%"
            image={product.thumbnail}
            alt={product.title}
            sx={{ objectFit: "contain", cursor: "pointer" }}
            onClick={() => {
              productNavigator(product, navigate);
            }}
          />

          <Box
            className="show-size"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              position: "absolute",
              bgcolor: "#ffffff",
              bottom: 0,
              p: 1,
              gap: 1,
              width: "100%",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                justifyContent: "center",
              }}
            >
              {product.size.map((s) => (
                <Typography
                  variant="body2"
                  sx={{
                    p: 1,
                    border: "1px solid #e9e9ed",
                    cursor: "pointer",
                    borderColor:
                      size === s.value
                        ? (theme) => theme.palette.primary.main
                        : "#e9e9ed",
                  }}
                  onClick={() => handleSize(s.value)}
                >
                  {s.value}
                </Typography>
              ))}
            </Box>
            {checkCartItem(product._id) ? (
              <Button
                disabled
                variant="outlined"
                size="large"
                sx={{ color: (theme) => theme.palette.primary.main }}
              >
                Already Added
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="large"
                disabled={!size}
                onClick={() => {
                  moveToCart(product._id);
                  removeProduct(wishId);
                }}
              >
                MOVE TO CART
              </Button>
            )}
          </Box>
        </Box>

        <Box
          onClick={() => {
            productNavigator(product, navigate);
          }}
        >
          <CardContent
            sx={{
              p: 1,
              "&:last-child": {
                pb: 1,
              },
            }}
          >
            <Typography variant="h4">{product.title}</Typography>
            <Typography
              variant="body3"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {product.description}
            </Typography>
            <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
              <Typography variant="h5">
                {priceFormat(product.discountedPrice, currencyData, currency)}
              </Typography>
              {product.discount ? (
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.primary.main }}
                >
                  ({product.discount}% OFF)
                </Typography>
              ) : null}
            </Box>
          </CardContent>
        </Box>
      </Card>
      <IconButton
        sx={{
          position: "absolute",
          top: 1,
          right: 1,
          color: (theme) => theme.palette.icon.main,
          "&:hover": {
            color: (theme) => theme.palette.primary.main,
          },
        }}
        onClick={() => {
          removeProduct(wishId);
        }}
      >
        <Close />
      </IconButton>
    </Box>
  );
};
