import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getApiUrl } from "../../apis/config";
import { getCurrentUserIdToken } from "../../auth/awsAurh/token";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import { getCartProductsData } from "../../Redux/Cart/action";
import { useTotal } from "./useTotal";

const OnlinePayment = () => {
  const { total } = useTotal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shippingAddress, setShippingAddress] = useState("");
  const { user } = useSelector((state) => state.user);
  const { address } = useSelector((state) => state.address);

  useEffect(() => {
    setShippingAddress(
      `${address.name}, ${address.addressDetails}, ${address.city}, ${address.state}-${address.pincode}, Mob: ${address.mobile}`
    );
  }, [address]);

  const initPayment = async (data) => {
    const token = await getCurrentUserIdToken();
    const headers = { Authorization: `Bearer ${token}` };
    const options = {
      key: process.env.RAZORPAY_KEY_ID,
      amount: data.amount,
      currency: data.currency,
      order_id: data.id,
      handler: async (response) => {
        try {
          const verifyUrl = `${getApiUrl}/payment/orders/verify`;
          const { data } = await axios.post(
            verifyUrl,
            {
              ...response,
              total,
              username: user ? user["custom:name"] : "",
              address: shippingAddress,
            },
            {
              headers: headers,
            }
          );
          if (data.message) {
            dispatch(getCartProductsData());
            navigate("/order-confirm");
            showSuccessNotification(data.message);
          }
        } catch (error) {
          showErrorNotification(error.message);
        }
      },
      theme: {
        color: "#e62e72",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePayment = async () => {
    const token = await getCurrentUserIdToken();
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const orderUrl = `${getApiUrl}/payment/orders/online`;
      const { data } = await axios.post(orderUrl, total, {
        headers: headers,
      });
      initPayment(data.data);
    } catch (error) {
      showErrorNotification(error.message);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5">
        Pay Online (Net Banking/Wallet/Card/UPI)
      </Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        You can pay via UPI/Wallet/Card/Net Banking and get some offer and
        discount.
      </Typography>
      <Button variant="contained" onClick={handlePayment} sx={{ mt: 2 }}>
        pay
      </Button>
    </Box>
  );
};

export default OnlinePayment;
