import { Box, TextField, Button, Slide } from "@mui/material";
import { useMutation } from "react-query";
import { useState } from "react";
import Navigation from "./Navigation";
import { showErrorNotification } from "../../notification/Notification";
import LoadingIndicator from "../../loading/LoadingIndicator";
import { getReduxUser, signin } from "../awsAurh";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginSuccess } from "../../Redux/Login/action";

const SigninForm = () => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    rememberMe: true,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    return signin(loginData.email, loginData.password);
  };

  const handleFieldChange = (e) => {
    setLoginData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };

  const signinMutation = useMutation(handleSubmit, {
    onSuccess: (user) => {
      dispatch(
        loginSuccess(getReduxUser(user, user.signInUserSession.idToken.payload))
      );
      user.signInUserSession.idToken.payload["custom:role"] === "admin"
        ? navigate("/dashboard/add-product")
        : navigate("/");
      // window.location.reload();
    },
    onError: (err) => {
      showErrorNotification(err.message);
    },
  });
  const isLoginDisabled =
    !loginData.email || !loginData.password || signinMutation.isLoading;
  return (
    <Slide direction="left" in>
      <Box sx={{ position: "relative" }}>
        <form onSubmit={signinMutation.mutate}>
          {signinMutation.isLoading && (
            <LoadingIndicator message="Signing in..." />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 375,
              margin: "0 auto",
              gap: 5,
            }}
          >
            <TextField
              value={loginData.email}
              name="email"
              placeholder="E-mail "
              onChange={handleFieldChange}
              fullWidth
            />
            <TextField
              value={loginData.password}
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleFieldChange}
              fullWidth
              sx={{ mt: 1 }}
            />

            <Button
              component={Link}
              to="/forgot-password"
              sx={{ p: 0, "&:hover": { backgroundColor: "transparent" } }}
            >
              Forgot your password?
            </Button>

            <Button
              disabled={isLoginDisabled}
              variant="contained"
              type="submit"
              size="large"
              fullWidth
              sx={{ p: "10px 30px 6px", borderRadius: 10 }}
            >
              Login
            </Button>
          </Box>
        </form>
        <Navigation />
      </Box>
    </Slide>
  );
};

export default SigninForm;
