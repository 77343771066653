import { KeyboardReturn, LocalShipping, Payments } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const data = [
  {
    text: "100% Secure Payments",
    icon: <Payments />,
  },
  {
    text: "Free Returns",
    icon: <KeyboardReturn />,
  },
  {
    text: "Free shipping",
    icon: <LocalShipping />,
  },
];

const ShippingCard = () => {
  return (
    <Box sx={{ display: "flex", gap: 3, mt: 3 }}>
      {data.map((item) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#f5f5f5",
            p: 2,
            borderRadius: 2,
            textAlign: "center",
            maxWidth: "130px",
            maxHeight: "100px",
          }}
        >
          {item.icon}
          <Typography variant="body3" sx={{ fontSize: "14px" }}>
            {item.text}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ShippingCard;
