import { Box, Typography } from "@mui/material";
import { contactUsData } from "./constent";
import { useEffect } from "react";
import imagePng from "./assets/contactuspng.png";

const HTMLContent = ({ htmlString }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ width: { xs: "90%", sm: "70%", md: "60%" }, m: "auto" }}>
      <Typography variant="h3" sx={{ mt: 4, textAlign: "center" }}>
        Contact Us
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row", md: "row" },
          gap: 10,
          my: 5,
          alignItems: "center",
        }}
      >
        <Box flex={2}>
          <Box
            sx={{ width: "100%" }}
            component={"img"}
            src={imagePng}
            alt="contact-us-img"
          />
        </Box>
        <Box sx={{ lineHeight: 2 }} flex={4}>
          <Typography variant="body2">{contactUsData.address}</Typography>
          <Typography variant="body2">
            <span style={{ fontWeight: "600" }}>Email Id:</span>{" "}
            {contactUsData.email}
          </Typography>
          <Typography variant="body2">
            <span style={{ fontWeight: "600" }}>Phone Number:</span>{" "}
            {contactUsData.mobile}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUsPage;
