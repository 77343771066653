import Address from "./Address";
import Wrapper from "./wrapper/Wrapper";

const ShippingAddress = () => (
  <Wrapper activeColor="3">
    <Address />
  </Wrapper>
);

export default ShippingAddress;
