import { Button, Box } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import Wrapper from "../wrapper/Wrapper";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import { getApiUrl } from "../../apis/config";
import axios from "axios";
import "./style.css";
import ProductForm from "./ProductForm";

const AddProduct = () => {
  const [loading, setLoading] = useState(false);

  const [size, setSize] = useState([
    {
      value: "",
      price: "",
    },
  ]);
  const [meta, setMeta] = useState([
    {
      key: "",
      value: "",
    },
  ]);
  const [data, setData] = useState({
    title: "",
    category: "",
    description: "",
    subcategory: "",
    type: "",
    designer: "",
    thumbnail: "",
    netPrice: "",
    discountedPrice: "",
    discount: 0,
    shippingTime: "",
    occasion: "",
    color: "",
    size: [],
    meta: [],
    isActive: true,
    bodySizeChart: "",
    sizeChart: "",
  });

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const emptyFeild = () => {
    setMeta([
      {
        key: "",
        value: "",
      },
    ]);
    setSize([
      {
        value: "",
        price: "",
      },
    ]);
    setData({
      title: "",
      category: "",
      description: "",
      subcategory: "",
      type: "",
      designer: "",
      thumbnail: "",
      netPrice: "",
      discountedPrice: "",
      discount: 0,
      shippingTime: "",
      occasion: "",
      color: "",
      size: [],
      meta: [],
      isActive: true,
      bodySizeChart: "",
      sizeChart: "",
    });
  };

  const handleChangeSize = (event, index) => {
    const { name, value } = event.target;

    const newSize = size[index];
    newSize[name] = value;
    size[index] = newSize;

    const newSizeData = size.filter((s) => s.value !== "");
    setData((prev) => ({ ...prev, size: newSizeData }));

    if (size.length - 1 === index) {
      setSize((prev) => [...prev, { value: "", price: "" }]);
    }
  };

  const handleRemoveSize = (index) => {
    const newSizeData = size.filter(
      (s, inx) => inx !== index && s.value !== ""
    );
    setData((prev) => ({ ...prev, size: newSizeData }));
    setSize([...newSizeData, { value: "", price: "" }]);
  };

  const handleChangeMeta = (event, index) => {
    const { name, value } = event.target;
    const newMeta = meta[index];
    newMeta[name] = value;
    meta[index] = newMeta;

    const newMetaData = meta.filter((m) => m.value !== "" && m.key !== "");
    setData((prev) => ({ ...prev, meta: newMetaData }));

    if (meta.length - 1 === index) {
      setMeta((prev) => [...prev, { key: "", value: "" }]);
    }
  };

  const handleRemoveMeta = (index) => {
    const newMetaData = meta.filter((s, inx) => inx !== index && s.key !== "");
    setData((prev) => ({ ...prev, meta: newMetaData }));
    setMeta([...newMetaData, { key: "", value: "" }]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(data);
    try {
      await axios({
        method: "POST",
        url: `${getApiUrl}/products/create`,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        console.log("res", res);
        showSuccessNotification("Successfully Added");
        setLoading(false);
        emptyFeild();
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      showErrorNotification(error.message);
    }
  };

  const handleImage = (event) => {
    setData((data) => ({
      ...data,
      thumbnail: event.target.files[0],
    }));
  };

  useEffect(() => {
    if (data.netPrice) {
      let totalValue = (
        data.netPrice -
        (data.netPrice * data.discount || 0) / 100
      ).toFixed(2);
      setData((prev) => ({ ...prev, ["discountedPrice"]: totalValue }));
    } else {
      setData((prev) => ({ ...prev, ["discountedPrice"]: "" }));
    }
  }, [data.discount, data.netPrice]);

  const isActive =
    !data.title ||
    !data.category ||
    !data.description ||
    !data.thumbnail ||
    !data.color.name ||
    !data.size.length === 0 ||
    !data.netPrice ||
    !data.isActive;

  return (
    <Wrapper active={2}>
      <Box sx={{ width: "60%", m: "auto" }}>
        <ProductForm
          handleSubmit={handleSubmit}
          data={data}
          setData={setData}
          loading={loading}
          handleChangeSize={handleChangeSize}
          handleImage={handleImage}
          handleFieldChange={handleFieldChange}
          handleRemoveSize={handleRemoveSize}
          size={size}
          meta={meta}
          handleChangeMeta={handleChangeMeta}
          handleRemoveMeta={handleRemoveMeta}
        />

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mr: 18, mt: -5 }}
        >
          <Button onClick={emptyFeild} sx={{ fontWeight: 600, fontSize: 20 }}>
            Reset
          </Button>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default AddProduct;
