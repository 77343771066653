import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MenuItems = ({ handleClose }) => {
  const navigate = useNavigate();
  const handleNavigation = (route) => {
    navigate(route);
    handleClose();
  };
  return (
    <>
      <MenuItem onClick={() => handleNavigation("/orders&returns")}>
        Orders & Returns
      </MenuItem>
      <MenuItem onClick={() => handleNavigation("/user-profile")}>
        Account Details
      </MenuItem>
      <MenuItem onClick={() => handleNavigation("/address")}>Address</MenuItem>
    </>
  );
};

export default MenuItems;
