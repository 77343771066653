import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";

export const useCurrencyConverter = () => {
  const [currencyData, setCurrencyData] = useState("");
  const currency = localStorage.getItem("currency");
  useEffect(() => {
    const fetchCurrencyData = async () => {
      try {
        const response = await axios.get(
          `https://api.exchangerate-api.com/v4/latest/${currency}`
        );
        setCurrencyData(response.data.rates["INR"]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCurrencyData();
  }, [currency]);
  return { currencyData, currency };
};
