import makeApiCall from "../../apis";

export const ORDER_PRODUCTS_LOADING = "ORDER_PRODUCTS_LOADING";
export const ORDER_PRODUCTS_SUCCESS = "ORDER_PRODUCTS_SUCCESS";
export const ORDER_PRODUCTS_ERROR = "ORDER_PRODUCTS_ERROR";

export const getOrderProductsLoading = () => ({
  type: ORDER_PRODUCTS_LOADING,
});

export const getOrderProductsSuccess = (payload) => ({
  type: ORDER_PRODUCTS_SUCCESS,
  payload,
});

export const getOrderProductsError = () => ({
  type: ORDER_PRODUCTS_ERROR,
});

export const getOrderProductsData = () => (dispatch) => {
  dispatch(getOrderProductsLoading());

  makeApiCall(`/payment/orders`, {
    method: "GET",
  })
    .then((res) => {
      dispatch(getOrderProductsSuccess(res));
    })
    .catch((error) => dispatch(getOrderProductsError()));
};
