import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import AppRoutes from "./Routes";
import { store } from "./Redux/store";
import ThemeProvider from "./uiCore/theme/ThemeProvider";
import { ToastContainer } from "react-toastify";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./apis/queryClient";
import "./App.css";

const App = () => (
  <>
    <ThemeProvider>
      <Provider store={store}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
          </QueryClientProvider>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
    <ToastContainer style={{ fontSize: "14px" }} />
  </>
);

export default App;
