const color = [
  "Beige",
  "Black",
  "White",
  "Blue",
  "Brown",
  "Green",
  "Grey",
  "Pink",
  "Purple",
  "Red",
  "Gold",
  "Maroon",
  "Multi Color",
  "Orange",
  "Yellow",
];

const categories = ["Western", "Indian", "Sports"];
export const occasionOption = [
  "Bride",
  "Mehendi & Haldi",
  "Sangeet",
  "Cocktail",
  "Wedding",
  "Reception",
  "Destination Wedding",
  "Resort",
  "Work",
  "Party",
  "Puja",
];

export { color, categories };
