import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getProductsData,
  getProductsError,
  getProductsLoading,
  getProductsSuccess,
} from "../../Redux/Products/action";
import { ProductCard } from "../card/ProductCard";
import LoadingIndicator from "../../loading/LoadingIndicator";
import FilterProducts from "./FilterProducts";
import makeApiCall from "../../apis";
import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import { Close, FilterAltOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";

export const ProductsPage = () => {
  const dispatch = useDispatch();
  const [allCategory, setAllCategory] = useState([]);
  const [allDesigner, setAllDesigner] = useState([]);
  const [allColor, setAllColor] = useState([]);
  const [sortVal, setSortVal] = useState();
  const [open, setOpen] = useState(false);
  const [priceRange, setPriceRange] = useState({
    max: "",
    min: "",
  });
  const [page, setPage] = useState(1);
  const { type, category, designer } = useParams();
  const [typePro, setTypePro] = useState("");
  const size = 40;
  const [filterType, setFilterType] = useState({
    category: [],
    designer: [],
    occasion: [],
    color: [],
  });

  const { products, totalPages, loading } = useSelector(
    (state) => state.products
  );

  useEffect(() => {
    if (category) {
      setFilterType((data) => ({ ...data, category: [category] }));
    }
    if (designer) {
      setFilterType((data) => ({ ...data, designer: [designer] }));
    }
  }, [category, designer, typePro, type]);

  useEffect(() => {
    filterByCategory();
    getCategoryAndDesigner();
  }, [filterType, sortVal, page, typePro]);

  useEffect(() => {
    getCategoryAndDesigner();
  }, [type, products]);

  useEffect(() => {
    setTypePro(type);
    if (type === "new") {
      setTypePro("");
    }
    if (type === "kids") {
      setTypePro("boys,girls");
    }
    setPriceRange({
      max: "",
      min: "",
    });
    setFilterType({ category: [], designer: [], color: [], occasion: [] });
    setPage(1)
  }, [type, category, designer]);

  const getCategoryAndDesigner = () => {
    makeApiCall(`/products/filter-option?type=${type}`, {
      method: "GET",
    })
      .then((res) => {
        setAllDesigner([...new Set(res.designer)]);
        setAllColor([...new Set(res.color)]);
        setAllCategory([...new Set(res.category)]);
      })
      .catch((error) => console.log(error));
  };

  const filterByCategory = () => {
    dispatch(getProductsLoading());
    makeApiCall(
      `/products?type=${typePro}&page=${page}&limit=${size}&category=${filterType.category?.join(
        ","
      )}&designer=${filterType.designer?.join(
        ","
      )}&occasion=${filterType.occasion?.join(
        ","
      )}&color=${filterType.color?.join(",")}&price[gte]=${
        priceRange.min
      }&price[lte]=${priceRange.max}&sort=${sortVal || ""}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        dispatch(getProductsSuccess(res));
        console.log(res);
      })
      .catch((error) => dispatch(getProductsError(error)));
  };

  console.log(filterType);
  console.log(category, designer, type);

  const handleChecked = (value, checked, type) => {
    if (checked)
      setFilterType((data) => ({ ...data, [type]: [...data[type], value] }));
    else
      setFilterType((data) => ({
        ...data,
        [type]: [...data[type].filter((item) => item !== value)],
      }));
  };

  const handleSelectSort = (event) => {
    setSortVal(event.target.value);
  };

  const handlePriceRange = (event) => {
    const { name, value } = event.target;
    setPriceRange((data) => ({ ...data, [name]: value }));
  };
  const filterByPriceRange = () => {
    filterByCategory();
  };

  const clearFilter = () => {
    dispatch(getProductsData(1, size));
    setPriceRange({
      max: "",
      min: "",
    });
    setFilterType({
      category: [],
      designer: [],
      occasion: [],
      color: [],
    });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const showClearBtn =
    filterType.category.length > 0 ||
    filterType.color.length > 0 ||
    priceRange.min ||
    priceRange.max;

  return (
    <Grid container sx={{ mt: 5 }} spacing={4}>
      <Grid item md={2.5} sm={12} xs={12} mt={3}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              display: {
                xs: "none",
                md: "flex",
                alignItems: "center",
                color: (theme) => theme.palette.primary.main,
              },
            }}
            variant="h3"
          >
            <FilterAltOutlined /> FILTER
          </Typography>

          {showClearBtn ? (
            <Button
              sx={{
                display: { xs: "none", md: "block" },
              }}
              onClick={clearFilter}
            >
              CLEAR ALL
            </Button>
          ) : null}
        </Box>
        <Drawer
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          sx={{
            display: { xs: "flex", md: "none" },
            flexDirection: "column",
            "& .MuiDrawer-paper": { p: 2 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={clearFilter}>CLEAR ALL</Button>
            <IconButton onClick={handleDrawerClose}>
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <FilterProducts
            handleChecked={handleChecked}
            handlePriceRange={handlePriceRange}
            filterByPriceRange={filterByPriceRange}
            filterType={filterType}
            priceRange={priceRange}
            allCategory={allCategory}
            allDesigner={allDesigner}
            allColor={allColor}
          />
        </Drawer>
        <Box sx={{ display: { xs: "none", md: "block" }, mt: 2 }}>
          <FilterProducts
            handleChecked={handleChecked}
            handlePriceRange={handlePriceRange}
            filterByPriceRange={filterByPriceRange}
            filterType={filterType}
            priceRange={priceRange}
            allCategory={allCategory}
            allDesigner={allDesigner}
            allColor={allColor}
          />
        </Box>
      </Grid>

      <Grid item md={9.5} sm={12} xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between", md: "end" },
            maxHeight: "30px",
          }}
        >
          <Button
            onClick={handleDrawerOpen}
            sx={{
              display: { xs: "flex", md: "none" },
              mr: 2,
              ...(open && { display: "none" }),
            }}
            variant="outlined"
            startIcon={<FilterAltOutlined />}
          >
            Filter
          </Button>

          <Box sx={{ minWidth: 180 }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="select-label">Sort By Price</InputLabel>
              <Select
                labelId="select-label"
                label="Sort By Price"
                onChange={handleSelectSort}
                rows={3}
                SelectDisplayProps={{
                  style: { padding: 8 },
                }}
              >
                <MenuItem value={1}>Low To High</MenuItem>
                <MenuItem value={-1}>High To Low</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        {loading ? (
          <LoadingIndicator />
        ) : products.length === 0 ? (
          <Box sx={{ textAlign: "center", minHeight: 400, pt: 10 }}>
            Product Not Found
          </Box>
        ) : (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "48% 48%", md: "30% 30% 30%" },
              gap: 2,
              justifyContent: "space-between",
              mt: 8,
            }}
          >
            {products.map((item) => (
              <ProductCard key={item._id} item={item} />
            ))}
          </Box>
        )}

        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Pagination
            count={totalPages}
            color="primary"
            onChange={(event, value) => {
              setPage(value);
            }}
            size="large"
          />
        </Box>
      </Grid>
    </Grid>
  );
};
