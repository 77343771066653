import { useMemo } from "react";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../Redux/Login/action";

export const getCurrentUserToken = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  let userId = "";
  if (token) {
    const decoded = jwt_decode(token);
    userId = decoded.user._id;
  }
  return { token, userId };
};

export const useCurrentUser = () => {
  const { token } = getCurrentUserToken();
  const dispatch = useDispatch();

  const user = useMemo(() => {
    if (!token) return;
    const decoded = jwt_decode(token);
    dispatch(
      loginSuccess({
        token: token,
        user: decoded.user,
        userId: decoded.user._id,
      })
    );
  }, [token, dispatch]);

  return user;
};
