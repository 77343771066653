import { Box } from "@mui/material";
import { policyData } from "./constent";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const HTMLContent = ({ htmlString }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

const PolicyPage = () => {
  const { policy } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [policy]);

  return (
    <Box sx={{ width: { xs: "90%", sm: "70%", md: "60%" }, m: "auto" }}>
      <HTMLContent htmlString={policyData[policy]} />
    </Box>
  );
};

export default PolicyPage;
