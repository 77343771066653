import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const OrderThanksPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "60%" },
        m: "auto",
        p: 5,
        mt: 8,
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: { xs: "30%", md: "20%" }, m: "auto" }}>
          <img
            style={{ width: "100%" }}
            src="order-confirm.png"
            alt="order-confirm"
          />
        </Box>
        <Typography variant="h3" sx={{ color: "green" }}>
          Order Confirmed
        </Typography>
        <Typography
          variant="body2"
          sx={{ width: { xs: "100%", md: "60%" }, m: "auto" }}
        >
          Your order is confirmed. You will receive an order confirmation
          email/SMS shortly with the expected delivery date for your items.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 4,
          mt: 5,
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            navigate("/products/new");
          }}
        >
          CONTINUE SHOPPING
        </Button>
        <Button
          variant="outlined"
          size="large"
          onClick={() => {
            navigate("/orders&returns");
          }}
        >
          YOUR ORDER
        </Button>
      </Box>
    </Box>
  );
};

export default OrderThanksPage;
