import {
  Box,
  Button,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import makeApiCall from "../../apis";
import LoadingIndicator from "../../loading/LoadingIndicator";
import { showErrorNotification } from "../../notification/Notification";
import Wrapper from "../wrapper/Wrapper";
import EditForm from "./EditForm";
import RowCard from "./RowCard";

const EditProduct = () => {
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    setSearchData([]);
  }, [editData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    makeApiCall(`/products/search?search=${text}`, {
      method: "GET",
    })
      .then((res) => {
        setSearchData([...res.products]);
        setShow(true);
        setLoading(false);
      })
      .catch((error) => showErrorNotification(error.message));
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleUpdateSearchData = (id) => {
    const data = searchData.filter((item) => item._id !== id);
    setSearchData([...data]);
  };

  return (
    <Wrapper active={3}>
      <Box sx={{ mt: 7, width: "70%", m: "auto" }}>
        <Box s>
          <List
            sx={{
              bgcolor: "background.paper",
              display: "flex",
              gap: 5,
              maxWidth: "80%",
              m: "auto",
            }}
            component="nav"
          >
            <ListItemButton
              sx={{
                textAlign: "center",
                borderBottom: "4px solid",
                borderColor:
                  active === 1
                    ? (theme) => theme.palette.primary.main
                    : "#ffffff",
              }}
              onClick={() => {
                setActive(1);
              }}
            >
              <ListItemText primary="Find Product" />
            </ListItemButton>
            <ListItemButton
              sx={{
                textAlign: "center",
                borderBottom: "4px solid",
                borderColor:
                  active === 2
                    ? (theme) => theme.palette.primary.main
                    : "#ffffff",
              }}
              disabled={active === 1}
            >
              <ListItemText primary="Edit" />
            </ListItemButton>
          </List>
        </Box>
        {active === 1 ? (
          <Box sx={{ mt: 4 }}>
            <Box>
              <InputLabel sx={{ mb: 1 }}>Search By Title</InputLabel>
              <TextField
                placeholder="enter..."
                fullWidth
                onChange={handleChange}
              />
              <Button
                variant="contained"
                type="submit"
                sx={{
                  p: "10px 30px 6px",
                  mt: 2,
                  borderRadius: 10,
                  maxWidth: "250px",
                }}
                onClick={handleSubmit}
              >
                Find Product
              </Button>
            </Box>

            {searchData.length === 0 && show && (
              <Box sx={{ mt: 6, textAlign: "center" }}>
                <Typography variant="h2">Product Not Found!</Typography>
              </Box>
            )}

            {loading ? (
              <LoadingIndicator />
            ) : (
              <Box sx={{ mt: 5 }}>
                {searchData.map((item) => (
                  <RowCard
                    item={item}
                    setActive={setActive}
                    setEditData={setEditData}
                    handleUpdateSearchData={handleUpdateSearchData}
                  />
                ))}
              </Box>
            )}
          </Box>
        ) : null}

        {active === 2 ? <EditForm productData={editData} /> : null}
      </Box>
    </Wrapper>
  );
};

export default EditProduct;
