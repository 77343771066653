const urlS3 = "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/";
export const bodySizeChartImage = [
  {
    "Women Body Chart":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/women-body-size.jpg",
  },
  {
    "Men Body Chart":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/Men+body+chart.jpg",
  },
];

export const productSizeChartImage = [
  {
    "Women Lehenga":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/Women+Lehenga.jpg",
  },
  {
    "Women Kurti":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/Women+Kurti.jpg",
  },
  {
    "Mens Sherwani":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/Mens+Sherwani.jpg",
  },
  {
    "Mens Nawabi":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/Mens+Nawabi.jpg",
  },
  {
    "Mens Jodhpuri Jacket":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/Mens+Jodhpuri+Jacket.jpg",
  },
  {
    "Mens Kurta & Chudihar":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/Mens+Kurta+%26+Chudihar.jpg",
  },
  {
    "MENS Jacket":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/MENS+Jacket.jpg",
  },
  {
    "MENS Jacket and kurta":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/MENS+Jacket+and+kurta.jpg",
  },
  {
    "Mens Indo Western":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/Mens+Indo+Western.jpg",
  },
  {
    "KIDS Jackets":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/KIDS+Sherwani.jpg",
  },
  {
    "KIDS Kurta Pajama":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/KIDS+kurta+pajama.jpg",
  },
  {
    "KIDS Sherwani":
      "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com/KIDS+Jackets.jpg",
  },
];
