import { keyframes } from "@emotion/react";
import { Box } from "@mui/material";

const animation = keyframes`
  25%  {box-shadow: -20px -20px, 20px 20px}
  50%  {box-shadow:   0px -20px,  0px 20px}
  75%  {box-shadow:  20px -20px,-20px 20px}
  100% {box-shadow:  20px   0px,-20px  0px}
`;
const LoadingIndicator = ({ message }) => (
  <Box
    sx={{
      top: 0,
      left: 0,
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      zIndex: 5,
      flexDirection: "column",
      background: "rgba(255,255,255,0.85)",
      width: "100%",
      height: "100%",
    }}
  >
    <Box
      sx={{
        width: "15px",
        aspectRatio: "1",
        borderRadius: "50%",
        background: (theme) => theme.palette.primary.main,
        boxShadow: "-20px 0px,20px 0px",
        animation: `${animation} 1s infinite`,
      }}
      title={message}
    />
  </Box>
);

export default LoadingIndicator;
