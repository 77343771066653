import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  ListItemIcon,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { imageUrl } from "../../Components/header/constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Wrapper = ({ children, active }) => {
  const navigate = useNavigate();
  const handleNavigate = (route, n) => {
    navigate(route);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: "90%", m: "auto", pt: 2 }}>
      <Box sx={{ width: 100 }}>
        <img
          style={{ width: "100%" }}
          src={`${imageUrl}/logo/logo.png`}
          alt="logo"
        />
      </Box>
      <Box sx={{ textAlign: "center", mt: -6 }}>
        <Typography variant="h3">Dashboard</Typography>
      </Box>
      <Box sx={{ borderBottom: "4px solid #E5E0FF" }}>
        <List
          sx={{
            bgcolor: "background.paper",
            display: "flex",
            gap: 1,
            maxWidth: "85%",
            m: "auto",
          }}
          component="nav"
        >
          {/* <ListItemButton
            sx={{
              textAlign: "center",
              borderBottom: "4px solid",
              borderColor:
                active === 1
                  ? (theme) => theme.palette.primary.main
                  : "#ffffff",
            }}
            onClick={() => {
              handleNavigate("/dashboard", 1);
            }}
          >
            <ListItemText primary="Admin" />
          </ListItemButton> */}
          <ListItemButton
            sx={{
              textAlign: "center",
              borderBottom: "4px solid",
              borderColor:
                active === 2
                  ? (theme) => theme.palette.primary.main
                  : "#ffffff",
            }}
            onClick={() => {
              handleNavigate("/dashboard/add-product", 2);
            }}
          >
            <ListItemText primary="Add Product" />
          </ListItemButton>
          <ListItemButton
            sx={{
              textAlign: "center",
              borderBottom: "4px solid",
              borderColor:
                active === 3
                  ? (theme) => theme.palette.primary.main
                  : "#ffffff",
            }}
            onClick={() => {
              handleNavigate("/dashboard/edit-product", 3);
            }}
          >
            <ListItemText primary="Edit Product" />
          </ListItemButton>
          <>
            <ListItemButton
              sx={{
                textAlign: "center",
                borderBottom: "4px solid",
                borderColor:
                  active === 4
                    ? (theme) => theme.palette.primary.main
                    : "#ffffff",
              }}
              onClick={handleClick}
            >
              <ListItemText primary="Order" />
              <ListItemIcon>
                <ArrowDropDown />
              </ListItemIcon>
            </ListItemButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{ p: 10 }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleNavigate("/dashboard/order", 4);
                }}
                sx={{ pl: { sm: 5, md: 10 }, pr: { sm: 5, md: 10 } }}
              >
                Continue
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleNavigate("/dashboard/order-delivered", 4);
                }}
                sx={{ pl: { sm: 5, md: 10 }, pr: { sm: 5, md: 10 } }}
              >
                Delivered
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleNavigate("/dashboard/order-cancel", 4);
                }}
                sx={{ pl: { sm: 5, md: 10 }, pr: { sm: 5, md: 10 } }}
              >
                Canceled
              </MenuItem>
            </Menu>
          </>
          <ListItemButton
            sx={{
              textAlign: "center",
              borderBottom: "4px solid",
              borderColor:
                active === 5
                  ? (theme) => theme.palette.primary.main
                  : "#ffffff",
            }}
            onClick={() => {
              handleNavigate("/dashboard/category-designer", 4);
            }}
          >
            <ListItemText primary="Add Category & Designer" />
          </ListItemButton>
          <ListItemButton
            sx={{
              textAlign: "center",
              borderBottom: "4px solid",
              borderColor:
                active === 6
                  ? (theme) => theme.palette.primary.main
                  : "#ffffff",
            }}
            onClick={() => {
              handleNavigate("/dashboard/coupon");
            }}
          >
            <ListItemText primary="Coupon" />
          </ListItemButton>
        </List>
      </Box>
      <Box sx={{ mt: 5, pb: 10 }}>{children}</Box>
    </Box>
  );
};

export default Wrapper;
