import { Box, TextField } from "@mui/material";

const MetaComponent = ({ handleChangeMeta, meta, index, sx }) => {
  return (
    <Box sx={{ display: "flex", gap: 5, mt: 3, ...sx }}>
      <TextField
        fullWidth
        type="text"
        label="Meta"
        onChange={(event) => {
          handleChangeMeta(event, index);
        }}
        value={meta.key}
        name="key"
      />

      <TextField
        fullWidth
        type="text"
        label="Value"
        onChange={(event) => {
          handleChangeMeta(event, index);
        }}
        value={meta.value}
        name="value"
      />
    </Box>
  );
};

export default MetaComponent;
