export const pages = [
  "New",
  "Men",
  "Women",
  "Kids",
  "Girls",
  "Boys",
  "Designer",
  "Jewellery",
  "Cosmetics",
  "Decor",
  "Gifts",
  "Sale",
  "Occasion",
];
export const imageUrl =
  "https://aapkafashionbucket.s3.ap-south-1.amazonaws.com";

export const currencyData = [
  "INR",
  "AUD",
  "GBP",
  "CAD",
  "HKD",
  "SGD",
  "USD",
  "EUR",
];
