import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getCurrentUserIdToken } from "./auth/awsAurh/token";

const ProtectedRoute = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  // const token = await getCurrentUserIdToken();

  const getToken = async () => {
    const token = await getCurrentUserIdToken();
    return token;
  };

  console.log(getToken());

  return getToken() ? children : <Navigate to="/signin" />;

  // if (loading) {
  //   return <CircularProgress />;
  // }
  // if (!user && !loading) {
  //   console.log(!currentRedirectUrl);
  //   if (!currentRedirectUrl) {
  //     console.log("currentRedirectUrl");
  //     dispatch(setRedirectUrl(location.pathname));
  //   }
  //   console.log("currentRedirectUrltttt");
  //   // return <Navigate to="/signin" replace />;
  // }
  // console.log(location.pathname, user, currentRedirectUrl);
  // if (user && currentRedirectUrl) {
  //   console.log("location.pathname");
  //   dispatch(setRedirectUrl(null));
  // }
  // console.log("location.pathname2");
  // return children;
};

export default ProtectedRoute;
