import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const MenuOption = ({ activeColor, style }) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        fontSize: "18px",
        fontWeight: 500,
        gap: 2,
        ...style,
      }}
    >
      <Link
        to="/orders&returns"
        style={{ color: activeColor === "1" && "red" }}
      >
        Orders & Returns
      </Link>
      <Link to="/user-profile" style={{ color: activeColor === "2" && "red" }}>
        Account Details
      </Link>
      <Link to="/address" style={{ color: activeColor === "3" && "red" }}>
        Address
      </Link>
    </Box>
  );
};

export default MenuOption;
