import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const EmptyCart = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ mt: 10, mb: 15 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          gap: 2,
          maxWidth: { xs: "80%", md: "50%" },
          m: "auto",
        }}
      >
        <Box sx={{ maxWidth: 80, m: "auto" }}>
          <img
            style={{ width: "100%" }}
            src="assets/cart/shopping-bag.png"
            alt="bag"
          />
        </Box>
        <h4>Hey, it feels so light!</h4>
        <Typography variant="body3">
          There is nothing in your bag. Let's add some items.
        </Typography>
        <Button
          sx={{ maxWidth: 200, m: "auto" }}
          variant="outlined"
          onClick={() => {
            navigate("/");
          }}
        >
          Add Item
        </Button>
      </Box>
    </Box>
  );
};

export default EmptyCart;
