import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const SocialComponent = () => {
  return (
    <Box className="social_icon">
      <Link
        target="_blank"
        to="https://instagram.com/aapkafashions?igshid=NzZlODBkYWE4Ng=="
      >
        <i className="bx bxl-instagram-alt"></i>
      </Link>
      <Link
        target="_blank"
        to="https://www.facebook.com/profile.php?id=100090254133504&mibextid=ZbWKwL"
      >
        <i class="bx bxl-facebook-square"></i>
      </Link>
      <Link target="_blank" to="https://wa.me/+918425031036">
        <i class="bx bxl-whatsapp-square"></i>
      </Link>
      <Link to="mailto:fashionsaapka@gmail.com">
        <i class="bx bxl-gmail"></i>
      </Link>
      <Link target="_blank" to="https://pin.it/2fxxqBY">
        <i class="bx bxl-pinterest"></i>
      </Link>
      <Link
        target="_blank"
        to="https://youtube.com/@AapkaFashions?si=rkdgPtr13ct-iNCU"
      >
        <i class="bx bxl-youtube"></i>
      </Link>
    </Box>
  );
};

export default SocialComponent;
