import { COGNITO_USER_POOL } from "./constants";

export const getCurrentUserIdToken = () =>
  new Promise((resolve, reject) => {
    COGNITO_USER_POOL.getCurrentUser()?.getSession((err, data) => {
      if (err || !data) {
        reject(err);
        return;
      }
      return resolve(data.getIdToken().getJwtToken());
    });
    return resolve(null);
  });
