import {
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Add, Delete, Remove } from "@mui/icons-material";
import makeApiCall from "../../apis";
import {
  getCartProductsData,
  getCartProductsLoading,
} from "../../Redux/Cart/action";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import { priceFormat, productNavigator, useCurrencyConverter } from "../utils";
import { useNavigate } from "react-router-dom";

export const CartPageCard = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currencyData, currency } = useCurrencyConverter();

  const handleCartQuantity = (quantity, productId) => {
    makeApiCall(`/cart/update/quantity`, {
      method: "PUT",
      body: JSON.stringify({ productId, quantity }),
    })
      .then(() => {
        dispatch(getCartProductsData());
      })
      .catch((error) => showErrorNotification(error.message));
  };

  const handleCartDelete = () => {
    dispatch(getCartProductsLoading());
    makeApiCall(`/cart/delete `, {
      method: "PUT",
      body: JSON.stringify({ productId: data.productId._id }),
    })
      .then((res) => {
        showSuccessNotification("Successfully Removed");
        dispatch(getCartProductsData());
      })

      .catch((error) => showErrorNotification(error.message));
  };

  return (
    <Card sx={{ display: "flex", mt: 3, position: "relative" }}>
      <CardMedia
        component="img"
        sx={{ width: 81, cursor: "pointer" }}
        image={data.productId.thumbnail}
        alt={data.productId.title}
        onClick={() => {
          productNavigator(data.productId, navigate);
        }}
      />
      <CardContent
        sx={{
          flex: "1 0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "92%",
        }}
      >
        <Box sx={{ width: "90%" }}>
          <Typography
            component="div"
            variant="h5"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              productNavigator(data.productId, navigate);
            }}
          >
            {data.productId.title}
          </Typography>
          <Typography variant="body3" className="ellipsis-line1">
            {data.productId.description}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: { xs: 1, md: 5 },
            alignItems: { xs: "start", md: "center" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
            <Typography variant="h5">Price:</Typography>
            <Typography variant="h5">
              {priceFormat(data.price, currencyData, currency)}
            </Typography>
          </Box>
          <Typography variant="h5">{`Size: ${data.size}`}</Typography>
          <Typography variant="h5">{`For: ${data.productId.type}`}</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 1 }}>
          <IconButton
            disabled={data.quantity === 1}
            sx={{
              width: "30px",
              height: "30px",
              bgcolor: (theme) => theme.palette.primary.main,
              color: "#ffffff",
              "&:hover": {
                bgcolor: (theme) => theme.palette.hover.main,
              },
            }}
            onClick={() => {
              handleCartQuantity(data.quantity - 1, data.productId._id);
            }}
          >
            <Remove />
          </IconButton>
          <Typography
            sx={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: 1,
              fontWeight: 600,
              borderColor: (theme) => theme.palette.primary.main,
            }}
            variant="body2"
          >
            {data.quantity}
          </Typography>
          <IconButton
            sx={{
              width: "30px",
              height: "30px",
              bgcolor: (theme) => theme.palette.primary.main,
              color: "#ffffff",
              "&:hover": {
                bgcolor: (theme) => theme.palette.hover.main,
              },
            }}
            onClick={() => {
              handleCartQuantity(data.quantity + 1, data.productId._id);
            }}
          >
            <Add />
          </IconButton>
        </Box>
      </CardContent>
      <IconButton
        onClick={handleCartDelete}
        sx={{ position: "absolute", right: 4, top: 4, color: "#ff1744" }}
      >
        <Delete />
      </IconButton>
    </Card>
  );
};
