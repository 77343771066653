import makeApiCall from "../../apis";

export const PRODUCT_CATEGORY_SUCCESS = "PRODUCT_CATEGORY_SUCCESS";
export const PRODUCT_DESIGNER_SUCCESS = "PRODUCT_DESIGNER_SUCCESS";

export const getCategorySuccess = (payload) => ({
  type: PRODUCT_CATEGORY_SUCCESS,
  payload,
});

export const getDesignerSuccess = (payload) => ({
  type: PRODUCT_DESIGNER_SUCCESS,
  payload,
});

export const getCategoryData = () => (dispatch) => {
  makeApiCall(`/category`, {
    method: "GET",
  })
    .then((res) => {
      console.log("category", res);
      dispatch(getCategorySuccess(res));
    })
    .catch((error) => console.log(error));
};

export const getDesignerData = () => (dispatch) => {
  makeApiCall(`/designer`, {
    method: "GET",
  })
    .then((res) => {
      console.log("designer", res);
      dispatch(getDesignerSuccess(res));
    })
    .catch((error) => console.log(error));
};
