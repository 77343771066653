import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import makeApiCall from "../../apis";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../notification/Notification";
import Wrapper from "../wrapper/Wrapper";

const AddProductCategory = () => {
  const [category, setCategory] = useState({
    category: "",
  });

  const [designer, setDesigner] = useState({
    designer: "",
  });

  const [msg, setMsg] = useState("");

  const handleCategory = (event) => {
    const { name, value } = event.target;

    setCategory((data) => ({ ...data, [name]: value }));
  };

  const handleDesigner = (event) => {
    const { name, value } = event.target;

    setDesigner({ [name]: value });
  };

  const handleSubmitCate = (event) => {
    event.preventDefault();
    makeApiCall(`/category`, {
      method: "POST",
      body: JSON.stringify(category),
    })
      .then((res) => {
        setCategory({
          category: "",
        });
        showSuccessNotification("Successfully Added");
      })
      .catch((error) => {
        console.log(error);
        showErrorNotification(error.message);
      });
  };

  const handleSubmitDesigner = (event) => {
    event.preventDefault();
    makeApiCall(`/designer`, {
      method: "POST",
      body: JSON.stringify(designer),
    })
      .then((res) => {
        showSuccessNotification("Successfully Added");
        setDesigner({
          designer: "",
        });
      })
      .catch((error) => showErrorNotification(error.message));
  };

  return (
    <Wrapper active={5}>
      <Box sx={{ width: "50%", m: "auto", mt: 4 }}>
        <Box>
          <InputLabel sx={{ mb: 1 }}>Category</InputLabel>
          <TextField
            name="category"
            placeholder="Category"
            fullWidth
            value={category.category}
            onChange={handleCategory}
          />
          {/* <FormControl fullWidth sx={{ mt: 3 }}>
            <InputLabel>Type</InputLabel>
            <Select
              label="Type"
              name="type"
              value={category.type}
              onChange={handleCategory}
            >
              <MenuItem value="men">Men</MenuItem>
              <MenuItem value="women">Women</MenuItem>
              <MenuItem value="Boys">Boys</MenuItem>
              <MenuItem value="girls">Girls</MenuItem>
              <MenuItem value="general">General</MenuItem>
            </Select>
          </FormControl> */}
          <Button
            variant="contained"
            type="submit"
            sx={{
              p: "10px 30px 6px",
              mt: 2,
              borderRadius: 10,
              maxWidth: "250px",
            }}
            onClick={handleSubmitCate}
          >
            Add Category
          </Button>
        </Box>
        <Box sx={{ mt: 7 }}>
          <InputLabel sx={{ mb: 1 }}>Designer</InputLabel>
          <TextField
            name="designer"
            placeholder="Designer"
            fullWidth
            value={designer.designer}
            onChange={handleDesigner}
          />
          <Button
            variant="contained"
            type="submit"
            sx={{
              p: "10px 30px 6px",
              mt: 2,
              borderRadius: 10,
              maxWidth: "250px",
            }}
            onClick={handleSubmitDesigner}
          >
            Add Designer
          </Button>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default AddProductCategory;
