import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import {
  COGNITO_USER_POOL,
  REACT_APP_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
} from "./constants";

export const getCurrentUser = () => COGNITO_USER_POOL.getCurrentUser();

export const signin = (email, password) =>
  new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: COGNITO_USER_POOL,
    });
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(result) {
        console.info(`[signin] onSuccess`, result);
        const idToken = result.getIdToken().getJwtToken();

        AWS.config.region = REACT_APP_COGNITO_REGION;

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
          Logins: {
            // Change the key below according to the specific region your user pool is in.
            [`cognito-idp.${REACT_APP_COGNITO_REGION}.amazonaws.com/${REACT_APP_COGNITO_USER_POOL_ID}`]:
              result.getIdToken().getJwtToken(),
          },
        });

        // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
        AWS.config.credentials.refresh((error) => {
          if (error) {
            console.error(error);
            return;
          }
          console.log("Successfully logged!");
        });
        console.info(`idToken`, idToken);
        resolve(cognitoUser);
      },

      onFailure(err) {
        console.error(err.message || JSON.stringify(err));
        reject(err);
      },
    });
  });

const confirmRegistration = (email, code) => {
  const userData = {
    Username: email,
    Pool: COGNITO_USER_POOL,
  };

  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, false, (err, result) => {
      if (err) {
        reject(err);
      }
      resolve(result);
    });
  });
};

export const completeSignup = async (data) => {
  console.info("[signup]", data);
  try {
    const confirmRegistrationResult = await confirmRegistration(
      data.email,
      data.code
    );
    console.info(`[signup] confirm registration`, confirmRegistrationResult);
    return true;
  } catch (err) {
    if (err) {
      console.error(err);
    }
    throw err;
  }
};

export const signupWithAttributes = (userData) => {
  let attributeList = [];
  // const userAttribute = { ...userData, DOB: "", gender: "" };

  // for (let key in userAttribute) {
  //   if (key !== "password" && key !== "email") {
  //     const attributeObject = {
  //       Name: `custom:${key}`,
  //       Value: userAttribute[key],
  //     };
  //     const attribute = new CognitoUserAttribute(attributeObject);
  //     attributeList.push(attribute);
  //   }
  // }

  // console.log(attributeList);

  const dataName = {
    Name: "custom:name",
    Value: userData.name,
  };

  const dataRole = {
    Name: "custom:role",
    Value: userData.role,
  };

  const dataPhoneNumber = {
    Name: "custom:mobile",
    Value: userData.mobile,
  };

  const attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);
  const attributeName = new CognitoUserAttribute(dataName);
  const attributeRole = new CognitoUserAttribute(dataRole);

  attributeList.push(attributeName);
  attributeList.push(attributePhoneNumber);
  attributeList.push(attributeRole);

  console.info(`User AttributeList: ${attributeList}`);

  return new Promise((resolve, reject) => {
    COGNITO_USER_POOL.signUp(
      userData.email,
      userData.password,
      attributeList,
      [],
      (err, result) => {
        if (err) {
          console.error("[signup]", err);
          reject(err);
        }

        if (!result) {
          console.error("[signup] no result");
          reject(new Error("Unknown error"));
          return;
        }

        console.info("[signup] sent OTP");
        resolve(result.user);
      }
    );
  });
};

export const resendConfirmationCode = (user) =>
  new Promise((resolve, reject) => {
    user.resendConfirmationCode((error) => {
      if (error) {
        console.error(error || JSON.stringify(error));
        return reject(error);
      }
      console.info("[signup] sent OTP");
      return resolve(true);
    });
  });

export const loadUser = () =>
  new Promise((resolve) => {
    const user = COGNITO_USER_POOL.getCurrentUser();
    if (!user) {
      resolve(null);
      return;
    }
    user.getSession((err, session) => {
      if (session?.isValid()) {
        user.getUserAttributes((getAttributesErr, attributes) => {
          if (getAttributesErr) {
            console.error(
              `Error while loading user attributes: ${getAttributesErr.message}`
            );
            resolve(null);
            return;
          }
          resolve({
            user,
            attributes:
              attributes?.reduce((acc, attribute) => {
                acc[attribute.Name] = attribute.Value;
                return acc;
              }, {}) || {},
          });
        });
        return;
      }

      if (err) {
        console.error(`Error while loading user: ${err.message}`);
      }
      resolve(null);
    });
  });

export const updateUserAttributes = (attributeArray) =>
  new Promise((resolve, reject) => {
    const user = COGNITO_USER_POOL.getCurrentUser();
    user.getSession((err, session) => {
      if (session?.isValid()) {
        user.updateAttributes(
          attributeArray,
          (updateAttributesError, result) => {
            if (updateAttributesError) {
              reject(updateAttributesError);
            }
            return resolve(result);
          }
        );
      }
      if (err) {
        console.error(`User not authenticate: ${err.message}`);
      }
      resolve(null);
    });
  });

export const getAttributes = () => {
  loadUser().then((data) => {
    if (!data) {
      return null;
    }
    const { attributes } = data;
    if (attributes) {
      return attributes;
    }
    return null;
  });
};

export const getReduxUser = (user, attributes) => ({
  userId: user.getUsername(),
  ...attributes,
});

export const signout = () => getCurrentUser()?.signOut();
