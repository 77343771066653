import { Box, Typography } from "@mui/material";

export const BigCard = ({ item }) => {
  return (
    <Box sx={{ maxWidth: "440px", textAlign: "center" }}>
      <Box className="img-hover-zoom">
        <img style={{ width: "99%" }} src={item.image} alt={item.title} />
      </Box>
      <Typography variant="h3" sx={{ fontSize: { xs: 14, sm: 16, md: 20 } }}>
        {item.title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: { xs: 12, sm: 14, md: 16 },
        }}
      >
        {item.description}
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontSize: { xs: 14, sm: 16, md: 20 }, fontWeight: 600 }}
      >
        Shop Now
      </Typography>
    </Box>
  );
};
