import {
  PRODUCT_FILTER_OPTION_SUCCESS,
  PRODUCT_FILTER_OPTION_LOADING,
  PRODUCT_FILTER_OPTION_ERROR,
} from "./action";

const initialState = {
  allCategory: [],
  allDesigner: [],
  allColor: [],
  loading: false,
  error: false,
};

export const filterOptionReducer = (
  store = initialState,
  { type, payload }
) => {
  switch (type) {
    case PRODUCT_FILTER_OPTION_LOADING:
      return {
        ...store,
        loading: true,
      };

    case PRODUCT_FILTER_OPTION_SUCCESS:
      return {
        ...store,
        loading: false,
        error: false,
        allDesigner: [...new Set(payload.designer)],
        allColor: [...new Set(payload.color)],
        allCategory: [...new Set(payload.category)],
      };

    case PRODUCT_FILTER_OPTION_ERROR:
      return {
        ...store,
        loading: false,
        error: true,
      };

    default:
      return store;
  }
};
