import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { getLogout } from "../../Redux/Login/action";
import { signout } from "../awsAurh";

const SignoutButton = () => {
  const dispatch = useDispatch();
  const handleSignout = () => {
    signout();
    dispatch(getLogout(null));
    window.location.reload();
  };
  return <Button onClick={handleSignout}>Logout</Button>;
};

export default SignoutButton;
