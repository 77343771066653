import { Add } from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import COD from "./COD";
import OnlinePayment from "./OnlinePayment";
import Wrapper from "./Wrapper";

const Payment = () => {
  const { address } = useSelector((state) => state.address);
  const [index, setIndex] = useState(1);

  const handleShow = (n) => {
    setIndex(n);
  };

  return (
    <Wrapper isShow={false}>
      <Box sx={{ width: { xs: "100%", md: "80%" } }}>
        <Box sx={{ p: 2, border: "1px solid #e9e9ed", borderRadius: "4px" }}>
          <Typography variant="h3">Address</Typography>
          <Typography variant="body2">{`${address.name}, ${address.mobile}, ${address.addressDetails}, ${address.city}, ${address.state}, ${address.pincode}`}</Typography>
        </Box>

        <Box
          sx={{ p: 2, mt: 5, border: "1px solid #e9e9ed", borderRadius: "4px" }}
        >
          <Typography variant="h2">Payment Method</Typography>
          <Box sx={{ mt: 3 }}>
            <ListItem
              sx={{ bgcolor: "#F0DBDB" }}
              disablePadding
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => {
                    index === 1 ? handleShow(null) : handleShow(1);
                  }}
                >
                  <Add />
                </IconButton>
              }
            >
              <ListItemButton
                onClick={() => {
                  index === 1 ? handleShow(null) : handleShow(1);
                }}
              >
                <ListItemText primary="UPI(Online Payment)" />
              </ListItemButton>
            </ListItem>
            <Collapse in={index === 1}>
              <OnlinePayment />
            </Collapse>
          </Box>

          <Box sx={{ mt: 3 }}>
            <ListItem
              sx={{ bgcolor: "#F0DBDB" }}
              disablePadding
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => {
                    index === 2 ? handleShow(null) : handleShow(2);
                  }}
                >
                  <Add />
                </IconButton>
              }
            >
              <ListItemButton
                onClick={() => {
                  index === 2 ? handleShow(null) : handleShow(2);
                }}
              >
                <ListItemText primary="COD(Cash On Delivery)" />
              </ListItemButton>
            </ListItem>
            <Collapse in={index === 2}>
              <COD />
            </Collapse>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default Payment;
