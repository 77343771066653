import { Box, Typography } from "@mui/material";
import logo from "./assets/logo.png";

const data = [
  {
    logo,
    text: "Chat With Us",
    source: "+918425031036",
    url: "https://wa.me/+918425031036",
  },
  {
    logo,
    text: "Contact Us",
    source: "+918425031036",
  },
  {
    logo,
    text: "Mail Us",
    source: "fashionsaapka@gmail.com",
    url: "mailto:fashionsaapka@gmail.com",
  },
];

const ContactCard = () => {
  return (
    <Box sx={{ display: "flex", gap: 3, mt: 2 }}>
      {data.map((item) => (
        <a href={item.url && item.url}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #D8D9CF",
              p: 2,
              borderRadius: 2,
              textAlign: "center",
            }}
          >
            <Box sx={{ maxWidth: "70px" }}>
              <Box sx={{ width: "100%" }} component="img" src={item.logo} />
            </Box>
            <Typography
              variant="body3"
              sx={{ fontSize: "16px", fontWeight: 600, lineHeight: 1.5 }}
            >
              {item.text}
            </Typography>
            <Typography variant="body3" sx={{ fontSize: "14px" }}>
              {item?.url !== "" ? (
                <a target="_blank" href={item.url}>
                  {item.source}
                </a>
              ) : (
                item.source
              )}
            </Typography>
          </Box>
        </a>
      ))}
    </Box>
  );
};

export default ContactCard;
