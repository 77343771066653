import "./footer.css";
import { Link } from "react-router-dom";
import { Box, List, Typography } from "@mui/material";
import { SiMastercard } from "react-icons/si";
import { RiVisaFill } from "react-icons/ri";
import Logo from "../header/logo/Logo";
import SocialComponent from "./SocialComponent";
import { policyMenu } from "./constant";
import { contactUsData } from "../aboutUs/constent";

export const Footer = () => {
  return (
    <Box className="footer">
      <Box className="about_section" sx={{ bgcolor: "#F5F5F5" }}>
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
            <Link to="/" style={{ marginLeft: -10, width: "70%" }}>
              <Logo />
            </Link>

            <Box sx={{ lineHeight: 2, mt: 3 }} flex={4}>
              <Typography variant="body2" style={{ fontSize: "14px" }}>
                {contactUsData.address}
              </Typography>
              <Typography variant="body2" style={{ fontSize: "14px" }}>
                <span style={{ fontWeight: "600" }}>Email Id:</span>{" "}
                {contactUsData.email}
              </Typography>
              <Typography variant="body2" style={{ fontSize: "14px" }}>
                <span style={{ fontWeight: "600" }}>Phone Number:</span>{" "}
                {contactUsData.mobile}
              </Typography>
            </Box>

            {/* <List sx={{ display: "flex", flexDirection: "column" }}>
              <li>
                <Link to="">About Us</Link>
              </li>
            </List> */}
          </Box>
          <Box>
            <h4>HELP</h4>
            <List sx={{ display: "flex", flexDirection: "column" }}>
              {policyMenu.map((item) => (
                <li>
                  <Link to={item.route}>{item.title}</Link>
                </li>
              ))}
            </List>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <h4>ACCOUNT</h4>
            <List sx={{ display: "flex", flexDirection: "column" }}>
              <li>
                <Link to="/orders&returns">Orders & Returns</Link>
              </li>
              <li>
                <Link to="/user-profile">Account Details</Link>
              </li>
            </List>
          </Box>
          <Box className="follow">
            <h4>FOLLOW US</h4>
            <SocialComponent />
          </Box>
          <Box>
            <h4>WE ACCEPT</h4>
            <List sx={{ display: "flex", gap: 4, mt: -3 }}>
              <li style={{ fontSize: 30 }}>
                <SiMastercard />
              </li>
              <li style={{ fontSize: 30 }}>
                <RiVisaFill />
              </li>
            </List>
          </Box>
        </Box>
      </Box>
      {/* <Box className="catagory_section">
        <Box>
          <Box>
            <h3>WOMEN</h3>
            <ul>
              <li>
                <Link to="">Indianwear</Link>
              </li>
              <li>
                <Link to="">Westernwear</Link>
              </li>
              <li>
                <Link to="">Bags</Link>
              </li>
              <li>
                <Link to="">Footwear</Link>
              </li>
              <li>
                <Link to="">Lingerie</Link>
              </li>
              <li>
                <Link to="">Sportswear</Link>
              </li>
            </ul>
          </Box>
          <Box>
            <h3>MEN</h3>
            <ul>
              <li>
                <Link to="">Indianwear</Link>
              </li>
              <li>
                <Link to="">Westernwear</Link>
              </li>
              <li>
                <Link to="">Bags</Link>
              </li>
              <li>
                <Link to="">Footwear</Link>
              </li>
              <li>
                <Link to="">Lingerie</Link>
              </li>
              <li>
                <Link to="">Sportswear</Link>
              </li>
            </ul>
          </Box>
          <Box>
            <h3>KIDS</h3>
            <ul>
              <li>
                <Link to="">Indianwear</Link>
              </li>
              <li>
                <Link to="">Westernwear</Link>
              </li>
              <li>
                <Link to="">Bags</Link>
              </li>
              <li>
                <Link to="">Footwear</Link>
              </li>
              <li>
                <Link to="">Lingerie</Link>
              </li>
              <li>
                <Link to="">Sportswear</Link>
              </li>
            </ul>
          </Box>
          <Box>
            <h3>TOP BRANDS</h3>
            <ul>
              <li>
                <Link to="">Aachho</Link>
              </li>
              <li>
                <Link to="">Forever</Link>
              </li>
              <li>
                <Link to="">New</Link>
              </li>
              <li>
                <Link to="">Reebok</Link>
              </li>
              <li>
                <Link to="">Adidas</Link>
              </li>
            </ul>
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
};
