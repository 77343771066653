import { Box, List, ListItemText, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { imageUrl } from "./constants";
import Kids from "./assets/kids.jpg";
import Men from "./assets/men.jpg";
import Women from "./assets/women.jpg";
import Wedding from "./assets/wedding.jpg";
import New from "./assets/new.jpg";

const SubMenuOption = ({ subMenu, type }) => {
  const navigate = useNavigate();
  const handleCateNavigate = (route) => {
    navigate(`products/category/${type}/${route}`);
  };

  const handleDesiNavigate = (route) => {
    navigate(`products/designer/${type}/${route}`);
  };

  const getImage = (img) => {
    console.log(img);
    if (img === "Men") return Men;
    else if (img === "Women") return Women;
    else if (img === "Kids" || img === "Girls" || img === "Boys") return Kids;
    else if (img === "Occasion") return Wedding;
    else return New;
  };

  return (
    <Box
      sx={{
        display: "flex",
        mt: 5,
        m: "auto",
        width: "95%",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box
        sx={{
          bgcolor: { xs: "transparent", md: "#F0EEED" },
          p: { xs: 2, md: 5 },
          flexGrow: 1,
        }}
      >
        <Typography variant="h4">Category</Typography>
        {subMenu.category.map((cat) => (
          <List
            key={cat}
            sx={{ cursor: "pointer", p: 0 }}
            onClick={() => handleCateNavigate(cat)}
          >
            <li>
              <ListItemText primary={cat} />
            </li>
          </List>
        ))}
      </Box>

      <Box
        sx={{
          p: { xs: 2, md: 5 },
          maxHeight: { xs: "auto", md: 400 },
          display: "flex",
          flexDirection: "column",
          flexWrap: { xs: "nowrap", md: "wrap" },
          flexGrow: 2,
        }}
      >
        <Typography variant="h4">Designer</Typography>
        {subMenu.designer.map((des) => (
          <List
            key={des}
            sx={{ cursor: "pointer", p: 0 }}
            onClick={() => handleDesiNavigate(des)}
          >
            <li>
              <ListItemText primary={des} />
            </li>
          </List>
        ))}
      </Box>
      {type !== "Jewellery" && type !== "Decor" && type !== "Gifts" ? (
        <Box
          sx={{
            bgcolor: "#F0EEED",
            maxWidth: 300,
            p: { xs: 2, md: 5 },
            display: { xs: "none", md: "block" },
            flexGrow: 1,
          }}
        >
          <img style={{ width: "100%" }} src={getImage(type)} alt={type} />
        </Box>
      ) : null}
    </Box>
  );
};

export default SubMenuOption;
