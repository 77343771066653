import { Box, TextField, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import LoadingIndicator from "../../../loading/LoadingIndicator";
import { completeSignup } from "../../awsAurh";
import { showErrorNotification } from "../../../notification/Notification";

const CompleteSignupForm = ({ user }) => {
  const navigate = useNavigate();
  const [signupData, setSignupData] = useState({
    email: user.getUsername(),
    otpSent: true,
    code: "",
  });
  const handleFieldChange = (e) => {
    setSignupData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(signupData);
    return completeSignup(signupData);
  };

  const signupMutation = useMutation(handleSubmit, {
    onSuccess: () => {
      console.info("Signup successful");
      return navigate("/signin");
    },
    onError: (err) => {
      showErrorNotification(err.message);
    },
  });
  const signupDisabled =
    !signupData.email || !signupData.code || signupMutation.isLoading;

  return (
    <form onSubmit={signupMutation.mutate}>
      {signupMutation.isLoading && (
        <LoadingIndicator message="Completing signup..." />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 375,
          margin: "0 auto",
          gap: 5,
        }}
      >
        <TextField
          type="text"
          name="email"
          placeholder="E-mail"
          value={signupData.email}
          onChange={handleFieldChange}
          variant="outlined"
        />

        <TextField
          type="text"
          name="code"
          placeholder="OTP"
          value={signupData.code}
          onChange={handleFieldChange}
          variant="outlined"
        />
        <Box>
          <Button
            disabled={signupDisabled}
            type="submit"
            variant="contained"
            size="large"
            sx={{ p: "10px 30px 6px", borderRadius: 10 }}
            fullWidth
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default CompleteSignupForm;
