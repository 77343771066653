import {
  Favorite,
  FavoriteBorder,
  KeyboardArrowDown,
  LocalMallOutlined,
  PersonOutline,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Badge,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SignoutButton from "../../auth/signout/Signout";
import { currencyData } from "./constants";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 6,
    background: theme.palette.primary.main,
    padding: "0 4px",
  },
}));

const settings = [
  { text: "Account Details", route: "/user-profile" },
  { text: "Orders & Returns", route: "/orders&returns" },
  { text: "Address", route: "/address" },
];

const ActionButton = ({ totalItem }) => {
  const { wishlistProducts } = useSelector((state) => state.wishlistProducts);
  const { user, userId, loading } = useSelector((state) => state.user);
  const [userName, setUserName] = useState("");
  const [defaultCurrency, setDefaultCurrency] = useState("");

  useEffect(() => {
    const currency = localStorage.getItem("currency");
    if (currency) setDefaultCurrency(currency);
    else setDefaultCurrency("INR");
  }, []);

  useEffect(() => {
    defaultCurrency && localStorage.setItem("currency", defaultCurrency);
  }, [defaultCurrency]);

  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElCurrency, setAnchorElCurrency] = useState(null);

  const handleMenuRoutes = (route) => {
    navigate(route);
    handleCloseUserMenu();
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenCurrencyMenu = (event) => {
    setAnchorElCurrency(event.currentTarget);
  };
  const handleCloseCurrencyMenu = () => {
    setAnchorElCurrency(null);
  };

  useEffect(() => {
    setUserName(user ? user["custom:name"] : "");
  }, [user, userId, loading]);

  const handleChangeCurrency = (event) => {
    setDefaultCurrency(event.target.value);
    handleCloseCurrencyMenu();
    window.location.reload();
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box>
        <Button
          onClick={handleOpenCurrencyMenu}
          variant="text"
          sx={{ p: 0, color: "#000000" }}
          endIcon={<KeyboardArrowDown />}
        >
          {defaultCurrency}
        </Button>

        <Menu
          sx={{ mt: "45px", p: 2 }}
          id="menu-appbar"
          anchorEl={anchorElCurrency}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElCurrency)}
          onClose={handleCloseCurrencyMenu}
        >
          <FormControl sx={{ minWidth: 100, p: 1, px: 3 }}>
            <RadioGroup value={defaultCurrency} onChange={handleChangeCurrency}>
              {currencyData.map((item) => (
                <FormControlLabel
                  value={item}
                  control={<Radio />}
                  label={item}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Menu>
      </Box>
      <Box>
        <IconButton onClick={handleOpenUserMenu}>
          <PersonOutline />
        </IconButton>

        <Menu
          sx={{ mt: "45px", p: 2 }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {userId ? (
            <Box>
              <MenuItem
                onClick={() => {
                  handleMenuRoutes("/user-profile");
                }}
              >
                <Typography textAlign="center">{`Hi, ${userName}`}</Typography>
              </MenuItem>
              {settings.map((setting) => (
                <MenuItem
                  key={setting.text}
                  onClick={() => {
                    handleMenuRoutes(setting.route);
                  }}
                >
                  <Typography textAlign="center">{setting.text}</Typography>
                </MenuItem>
              ))}
              <SignoutButton />
            </Box>
          ) : (
            <Box>
              <MenuItem
                onClick={() => {
                  handleMenuRoutes("/signin");
                }}
              >
                <Typography textAlign="center">Login</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMenuRoutes("/signup");
                }}
              >
                <Typography textAlign="center">Signup</Typography>
              </MenuItem>
            </Box>
          )}
        </Menu>
      </Box>

      <IconButton
        sx={{
          color:
            wishlistProducts.length > 0
              ? (theme) => theme.palette.primary.main
              : "#0000008a",
        }}
        onClick={() => {
          navigate("/wishlist");
        }}
      >
        {wishlistProducts.length > 0 ? <Favorite /> : <FavoriteBorder />}
      </IconButton>

      <IconButton
        onClick={() => {
          navigate("/cart");
        }}
      >
        {totalItem > 0 ? (
          <StyledBadge badgeContent={totalItem} color="secondary">
            <LocalMallOutlined />
          </StyledBadge>
        ) : (
          <LocalMallOutlined />
        )}
      </IconButton>
    </Box>
  );
};

export default ActionButton;
